/* istanbul ignore file */
import { useEffect, useMemo, useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addDays, addMinutes, isAfter, isEqual, isSameDay, isSameMinute, parse } from 'date-fns';

import { BDDrive, DriveStatus, DropdownOption, PlDriver, PlVehicle } from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { Button, Field } from '@components';
import { TIME_INTERVAL } from '@common/constants';
import { getFullDate } from '@common/utils';
import { DriveEditFormType } from '../../../schema/DriveForm.schema';
import { mapDriverOptions, mapVehicleOptions } from '../../../utils';

type Limits = { minDate: Date | undefined; minTime: Date | undefined; maxTime: Date | undefined };
type TimeProps = { pickupDate?: Date; pickupTime?: Date; dropoffDate?: Date; dropoffTime?: Date };

const initLimits = { minDate: undefined, minTime: undefined, maxTime: undefined };
const maxTime = parse('23:45', DF.ApiTime, new Date());

interface FieldsetProps {
  drive: BDDrive;
  drivers: PlDriver[];
  vehicles: PlVehicle[];
  onCancel: (driveId: string) => void;
  onChange?: () => void;
  setValue: UseFormSetValue<DriveEditFormType>;
  watch: UseFormWatch<DriveEditFormType>;
}

const EditDriveFieldset = ({
  drive,
  drivers,
  vehicles,
  onCancel,
  onChange,
  setValue,
  watch,
}: FieldsetProps) => {
  const { t } = useTranslation();
  const isLegDisabled = drive.state !== DriveStatus.NotStarted;
  const leg = Number(drive?.driveNumber || 1) - 1;

  const [isConfirm, setConfirm] = useState(false);
  const [pickupLimit, setPickupLimit] = useState<Limits>(initLimits);
  const [dropoffLimit, setDropoffLimit] = useState<Limits>(initLimits);

  const [dropoffDate$, dropoffTime$, pickupDate$, pickupTime$] = watch([
    `drives.${leg}.dropoffDate`,
    `drives.${leg}.dropoffTime`,
    `drives.${leg}.pickupDate`,
    `drives.${leg}.pickupTime`,
  ]);

  const driverOptions: DropdownOption[] = useMemo(() => mapDriverOptions(drivers), [drivers]);
  const vehicleOptions: DropdownOption[] = useMemo(() => mapVehicleOptions(vehicles), [vehicles]);

  const updateDateTimeConstraints = (e: TimeProps) => {
    onChange?.();
    const pickupDate = e.pickupDate || pickupDate$;
    const pickupTime = e.pickupTime || pickupTime$;
    const dropoffDate = e.dropoffDate || dropoffDate$;
    const dropoffTime = e.dropoffTime || dropoffTime$;

    if (!pickupDate && !dropoffDate && !pickupTime) return;

    setDropoffLimit((s) => ({ ...s, minDate: pickupDate }));

    // set dropoffDate
    if (dropoffDate && isAfter(pickupDate, dropoffDate)) {
      setValue(`drives.${leg}.dropoffDate`, pickupDate);
    }

    if (dropoffTime) {
      const isSameDate = isSameDay(pickupDate, dropoffDate);
      const isSameOrAfterTime =
        (isSameDate || isAfter(pickupDate, dropoffDate)) &&
        (isEqual(pickupTime, dropoffTime) || isAfter(pickupTime, dropoffTime));
      // set dropoffTime
      if (isSameDate) {
        setDropoffLimit((s) => ({ ...s, maxTime, minTime: addMinutes(pickupTime, TIME_INTERVAL) }));
      }

      if (isSameOrAfterTime) {
        const pickup = getFullDate(pickupDate, pickupTime);
        const dropoff = getFullDate(dropoffDate, dropoffTime);
        const isNextDay =
          isSameMinute(pickupTime, maxTime) &&
          (isEqual(pickup, dropoff) || isAfter(pickup, dropoff));
        // set dropoffDate
        if (isNextDay) setValue(`drives.${leg}.dropoffDate`, addDays(pickupDate, 1));
        // set dropoffTime
        setValue(`drives.${leg}.dropoffTime`, addMinutes(pickupTime, TIME_INTERVAL));
      }
    }
  };

  useEffect(() => {
    if (pickupDate$ && dropoffDate$ && isSameDay(pickupDate$, dropoffDate$)) {
      if (isAfter(pickupTime$, dropoffTime$) || isSameMinute(dropoffTime$, pickupTime$)) {
        setValue(`drives.${leg}.dropoffTime`, addMinutes(pickupTime$, TIME_INTERVAL));
      }

      setDropoffLimit((s) => ({ ...s, maxTime, minTime: addMinutes(pickupTime$, TIME_INTERVAL) }));
    } else {
      setDropoffLimit((s) => ({ ...s, maxTime: undefined, minTime: undefined }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupDate$, dropoffDate$]);

  useEffect(() => {
    setPickupLimit((s) => ({ ...s, maxTime: undefined, minTime: undefined }));
  }, []);

  return (
    <section className="bp-leg-form-edit-drive-item">
      <fieldset className="bp-leg-form-fieldset">
        <section className="bp-leg-form-fieldset-row">
          <Field
            name={`drives.${leg}.pickupDate`}
            input="date-picker"
            disabled={isLegDisabled}
            hideOutsideDates
            label={t('bookingDetails.pickupDate')}
            minDate={pickupLimit.minDate}
            valueFormat="DD.MM.YYYY"
            variant="unstyled"
            onChange={(pickupDate: Date) => updateDateTimeConstraints({ pickupDate })}
          />
          <Field
            name={`drives.${leg}.pickupTime`}
            input="time-picker"
            disabled={isLegDisabled}
            label={t('bookingDetails.pickupTime')}
            maxTime={pickupLimit.maxTime}
            minTime={pickupLimit.minTime}
            valueTypeDate
            variant="unstyled"
            onChange={(pickupTime: Date) => updateDateTimeConstraints({ pickupTime })}
          />
          <Field
            name={`drives.${leg}.dropoffDate`}
            input="date-picker"
            disabled={isLegDisabled}
            hideOutsideDates
            label={t('bookingDetails.dropoffDate')}
            minDate={dropoffLimit.minDate}
            valueFormat="DD.MM.YYYY"
            variant="unstyled"
            onChange={(dropoffDate: Date) => updateDateTimeConstraints({ dropoffDate })}
          />
          <Field
            name={`drives.${leg}.dropoffTime`}
            input="time-picker"
            disabled={isLegDisabled}
            label={t('bookingDetails.dropoffTime')}
            maxTime={dropoffLimit.maxTime}
            minTime={dropoffLimit.minTime}
            valueTypeDate
            variant="unstyled"
            onChange={(dropoffTime: Date) => updateDateTimeConstraints({ dropoffTime })}
            data-testid={`planner-booking-panel-dropoff-time-${leg}`}
          />
          <Field
            name={`drives.${leg}.vehicle`}
            input="dropdown"
            disabled={isLegDisabled}
            label={t('mobility.selectVehicle')}
            options={vehicleOptions}
            placeholder={`${t('common.select')}...`}
            variant="unstyled"
            onChange={(vehicle: string) => {
              onChange?.();
              const driver = vehicles?.find((i: PlVehicle) => i.id === vehicle)?.preferredDriver;
              if (driver) setValue(`drives.${leg}.driver`, driver);
            }}
            data-testid={`planner-booking-panel-vehicle-${leg}`}
          />
          <Field
            name={`drives.${leg}.driver`}
            input="dropdown"
            disabled={isLegDisabled}
            label={t('mobility.selectDriver')}
            options={driverOptions}
            placeholder={`${t('common.select')}...`}
            variant="unstyled"
            onChange={(driver: string) => {
              onChange?.();
              const vehicle = drivers?.find((i) => i.id === driver)?.preferredVehicle;
              if (vehicle) setValue(`drives.${leg}.vehicle`, vehicle);
            }}
          />
        </section>
        <section className="bp-leg-form-fieldset-row">
          <Field
            name={`drives.${leg}.commentPax`}
            input="textarea"
            disabled={isLegDisabled}
            label={t('planner.commentPax')}
            placeholder="(optional)"
            variant="unstyled"
          />
          <Field
            name={`drives.${leg}.commentDriver`}
            input="textarea"
            disabled={isLegDisabled}
            label={t('planner.commentDriver')}
            placeholder="(optional)"
            variant="unstyled"
          />
        </section>
      </fieldset>

      <div className="bp-leg-form-ctrl">
        {isConfirm ? (
          <>
            <p>{t('planner.msgCancelDriveConfirm')}</p>
            <div className="bp-leg-form-buttons">
              <Button
                className="btn"
                text={t('common.yes')}
                type="button"
                variant="danger"
                onClick={() => {
                  onCancel(drive?.id);
                  setConfirm(false);
                }}
              />
              <Button
                className="btn"
                text={t('common.no')}
                variant="submit"
                onClick={() => setConfirm(false)}
              />
            </div>
          </>
        ) : null}

        {drive.state === DriveStatus.NotStarted && !isConfirm ? (
          <div className="bp-leg-form-buttons">
            <Button
              className="btn"
              disabled={!drive.isActive}
              text={t('common.btnCancel')}
              variant="danger"
              onClick={() => setConfirm(true)}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default EditDriveFieldset;
