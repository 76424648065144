import { type FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Loader, PageTitle } from '@components';
import { useFacility } from '@common/hooks';
import { useRepository } from '@context';
import { CostsType, DriveCostsApi, DriveCostsType } from '@common/interfaces';
import { Serializer } from '@common/utils';
import './ServiceCostSettingsPage.styles.scss';

const CostItem: FC<{ label: string; value?: number }> = ({ label, value }) => (
  <li>
    <div className="label">{label}</div>
    <div className="value">{value ?? '-'}</div>
  </li>
);

const ServiceCostSettingsPage: FC = () => {
  const { t } = useTranslation();
  const { financialRepo } = useRepository();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, city, country } = facility;

  const [driveCosts, setDriveCosts] = useState<DriveCostsType | null>(null);

  const { isLoading } = useQuery(
    'get-drive-costs',
    () => financialRepo.getDriveCosts(facilityId, { agencyId }),
    {
      enabled: !!agencyId,
      onSuccess: (data: DriveCostsApi) => {
        if (data) setDriveCosts(Serializer.formatDriveCosts(data));
      },
    },
  );

  const renderCosts = (title: string, costs: CostsType) => {
    const isHourEqual = costs.diesel.perHour === costs.petrol.perHour;
    const isKmEqual = costs.diesel.perKm === costs.petrol.perKm;

    return (
      <div className="vehicle-costs">
        <h2>{title}</h2>
        <ul>
          {isHourEqual ? (
            <CostItem label="Internal Combustion Engine (per hour)" value={costs.diesel.perHour} />
          ) : (
            <>
              <CostItem label="Diesel (per hour)" value={costs.diesel.perHour} />
              <CostItem label="Petrol (per hour)" value={costs.petrol.perHour} />
            </>
          )}

          {isKmEqual ? (
            <CostItem label="Internal Combustion Engine (per km)" value={costs.diesel.perKm} />
          ) : (
            <>
              <CostItem label="Diesel (per km)" value={costs.diesel.perKm} />
              <CostItem label="Petrol (per km)" value={costs.petrol.perKm} />
            </>
          )}

          <CostItem label="Electric (per hour)" value={costs.electric.perHour} />
          <CostItem label="Electric (per km)" value={costs.electric.perKm} />
        </ul>
      </div>
    );
  };

  return (
    <section className="service-cost-settings-page">
      <PageTitle
        title={`${t('mobility.serviceCostSettingsTitle')} ${city}, ${country} | ${agencyName}`}
        bottomLine
      />

      <Loader fullScreen spinning={isLoading} />

      <div className="drive-costs">
        {driveCosts ? (
          <>
            {renderCosts('Soft Vehicle', driveCosts.soft)}
            {renderCosts('Armored Vehicle', driveCosts.armoured)}
          </>
        ) : (
          <div className="drive-costs-empty">{t('mobility.noCostSettings')}</div>
        )}
      </div>
    </section>
  );
};

export default ServiceCostSettingsPage;
