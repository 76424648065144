/* istanbul ignore file */
import { DropdownOption } from '@common/interfaces';
import { format } from 'date-fns';

const currentDate = new Date();
const thisYear = currentDate.getFullYear();

export const YEARS: DropdownOption[] = Array.from({ length: 5 }, (_, idx) => {
  const year = thisYear - idx;
  return { value: String(year), label: String(year) };
});

export const MONTHS: DropdownOption[] = Array.from({ length: 12 }, (_, idx) => ({
  label: format(new Date(0, idx), 'MMMM'),
  value: String(idx + 1),
}));

export const CURRENT_MONTH =
  MONTHS.find((m) => m.value === String(currentDate.getMonth() + 1)) || MONTHS[0];
export const CURRENT_YEAR = YEARS.find((y) => y.value === String(thisYear))!;
