/* eslint-disable @typescript-eslint/no-use-before-define */
import { LocalStorageKeys } from '@common/constants';
import { useMonolithFeatureFlags } from '@common/hooks';
import { WfpChatbot, type WfpChatbotProps } from './WfpChatbot';

const get = (x: string) => (localStorage.getItem(x) ?? '').replace(/^"|"$/g, '');
const K = LocalStorageKeys;

const role2TokenMap = {
  DRIVER: '743f36da-966f-4401-8920-47cb495f51b7',
  FP: '743f36da-966f-4401-8920-47cb495f51b7',
  PASSENGER: '6932c570-f526-4c77-b6c1-4b241e3597db',
};

export interface ChatbotProps {
  style?: WfpChatbotProps['style'];
}

export function Chatbot(props: ChatbotProps) {
  const { style } = props;
  const { flags } = useMonolithFeatureFlags();
  const token = get(K.REFRESH_TOKEN);
  const user = get(K.USER_EMAIL) || get(K.USER_NAME);
  const thread = role2TokenMap.PASSENGER;
  const iframeSrc = process.env.REACT_APP_CHATBOT_HOST_URL ?? '';

  return flags.show_chatbot?.is_active ? (
    <WfpChatbot iframeSrc={iframeSrc} token={token} user={user} thread={thread} style={style} />
  ) : null;
}

export default Chatbot;
