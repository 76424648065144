import { type FC, useMemo, useState } from 'react';
import type { AxiosError } from 'axios';
import type { DatesRangeValue } from '@mantine/dates';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, subDays } from 'date-fns';
import { Dropdown as FiltersDropdown, Pagination } from '@unbooking/ui-modules';

import { Download } from '@assets/svg/icons';
import { TABLE_ITEMS } from '@common/constants';
import {
  ApiList,
  DropdownOption,
  FeedbackUpdateApi,
  FeedbackListApi,
  IFeedbacksList,
} from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { DateFormat } from '@common/types';
import { Serializer } from '@common/utils';
import { useRepository } from '@context';
import { AverageRating, Button, DateInputPicker, Loader, PageTitle, Tabs } from '@components';
import { Feedback } from './components';
import './FeedbacksPage.styles.scss';

const FeedbacksPage: FC = () => {
  const { t } = useTranslation();
  const { feedbackRepo } = useRepository();
  const { agencyName, facility, facilityId } = useFacility();
  const { agencyId, aspAvgRating, city, country } = facility;

  const tabs = useMemo(() => [t('feedback.pending'), t('feedback.reviewed')], [t]);
  const ownAgencyOptions = useMemo(
    () => [
      { label: t('common.yes'), value: 'True' },
      { label: t('common.no'), value: 'False' },
    ],
    [t],
  );

  const [datePeriod, setDatePeriod] = useState<DatesRangeValue>([
    subDays(new Date(), 6),
    new Date(),
  ]);
  const [filterOwnAgency, setFilterOwnAgency] = useState<DropdownOption>(ownAgencyOptions[0]);
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);

  const [pendingFeedbacks, setPendingFeedbacks] = useState<IFeedbacksList[]>([]);
  const [pendingFeedbacksPage, setPendingFeedbacksPage] = useState<number>(1);
  const [pendingFeedbacksPageSize, setPendingFeedbacksPageSize] = useState<number>(10);
  const [pendingFeedbacksTotal, setPendingFeedbacksTotal] = useState<number>(0);

  const [processedFeedbacks, setProcessedFeedbacks] = useState<IFeedbacksList[]>([]);
  const [processedFeedbacksPage, setProcessedFeedbacksPage] = useState<number>(1);
  const [processedFeedbacksPageSize, setProcessedFeedbacksPageSize] = useState<number>(10);
  const [processedFeedbacksTotal, setProcessedFeedbacksTotal] = useState<number>(0);

  const createdAfter = datePeriod[0] ? format(datePeriod[0], DateFormat.ApiDate) : undefined;
  const createdBefore = datePeriod[1] ? format(datePeriod[1], DateFormat.ApiDate) : undefined;

  const fetchFeedbacks = (reviewed: boolean) =>
    feedbackRepo.getFeedbacks(facilityId, {
      createdAfter,
      createdBefore,
      limit: reviewed ? processedFeedbacksPageSize : pendingFeedbacksPageSize,
      offset: reviewed ? (processedFeedbacksPage - 1) * 10 : (pendingFeedbacksPage - 1) * 10,
      passengerAgency: filterOwnAgency?.value === 'True' ? agencyId : `-${agencyId}`,
      reviewed,
      vehicleAgency: agencyId,
    });

  const { isLoading: isPendingFeedbacksLoading, refetch: getPendingFeedbacks } = useQuery(
    [
      'pending-feedbacks',
      datePeriod,
      filterOwnAgency,
      pendingFeedbacksPage,
      pendingFeedbacksPageSize,
      tabs.indexOf(selectedTab) === 0,
    ],
    () => fetchFeedbacks(false),
    {
      enabled: Boolean(agencyId && datePeriod[0] && datePeriod[1]),
      onSuccess(data: ApiList<FeedbackListApi>) {
        setPendingFeedbacks(data.results.map(Serializer.formatFeedback));
        setPendingFeedbacksTotal(data.count);
      },
    },
  );

  const { isLoading: isProcessedFeedbacksLoading, refetch: getProcessedFeedbacks } = useQuery(
    [
      'processed-feedbacks',
      datePeriod,
      filterOwnAgency,
      processedFeedbacksPage,
      processedFeedbacksPageSize,
      tabs.indexOf(selectedTab) === 1,
    ],
    () => fetchFeedbacks(true),
    {
      enabled: Boolean(agencyId && datePeriod[0] && datePeriod[1]),
      onSuccess(data: ApiList<FeedbackListApi>) {
        setProcessedFeedbacks(data.results.map(Serializer.formatFeedback));
        setProcessedFeedbacksTotal(data.count);
      },
    },
  );

  const { mutate: getFeedbacksExport, isLoading: fileLoading } = useMutation<
    unknown,
    AxiosError,
    { reviewed: boolean }
  >(
    'feedbacks-export',
    ({ reviewed }) =>
      feedbackRepo.exportFeedbacks(facilityId, {
        createdAfter,
        createdBefore,
        passengerAgency: filterOwnAgency?.value === 'True' ? agencyId : `-${agencyId}`,
        reviewed,
        vehicleAgency: agencyId,
      }),
    {
      onError: (error: any) => {
        if (error?.response) toast.error(t('common.msgErrorExport'));
      },
    },
  );

  const { mutate: updateFeedbackStatus, isLoading: isFeedbackProcessing } = useMutation<
    FeedbackUpdateApi,
    AxiosError,
    { feedbackId: string; feedbackData: FeedbackUpdateApi }
  >(
    'update-feedback-status',
    ({ feedbackId, feedbackData }) =>
      feedbackRepo.updateFeedbackStatus(facilityId, feedbackId, feedbackData),
    {
      onSuccess: ({ status }) => {
        toast.success(t('feedback.statusChanged'));
        if (status === 'not_processed') {
          if (pendingFeedbacksPage === 1) {
            getPendingFeedbacks();
          } else {
            setPendingFeedbacksPage(1);
          }
          getProcessedFeedbacks();
        } else {
          if (processedFeedbacksPage === 1) {
            getProcessedFeedbacks();
          } else {
            setProcessedFeedbacksPage(1);
          }
          getPendingFeedbacks();
        }
      },
      onError: (error) => {
        if (error.message) {
          toast.error(error.message || t('common.errorMsgDefault'));
        }
      },
    },
  );

  const getColorFromRate = (rate: number): 'red' | 'yellow' | 'green' => {
    if (rate > 3) return 'green';
    if (rate === 3) return 'yellow';
    return 'red';
  };

  const isLoading =
    isPendingFeedbacksLoading || isProcessedFeedbacksLoading || isFeedbackProcessing;

  return (
    <section className="hbh-container feedbacks">
      <PageTitle
        bottomLine
        title={
          city && country ? `${t('feedback.pageTitle')} ${city}, ${country} | ${agencyName}` : ''
        }
        tools={
          <>
            <FiltersDropdown
              className="field select dark"
              options={ownAgencyOptions}
              label={<span className="hbh-select-label">Is own agency</span>}
              value={filterOwnAgency}
              onChange={setFilterOwnAgency}
            />

            <DateInputPicker
              format="DD MMM YYYY"
              label={`${t('feedback.timePeriod')}:`}
              placeholder="Choose dates range"
              type="range"
              variant="dark"
              value={datePeriod}
              onChange={(v) => setDatePeriod(v as DatesRangeValue)}
            />

            <AverageRating
              backgroundColor={getColorFromRate(aspAvgRating ?? 0)}
              facilityName={country}
              type="brief"
              value={aspAvgRating ? parseFloat(aspAvgRating?.toFixed(1)) : undefined}
            />
          </>
        }
      />
      <section className="feedbacks-content">
        <Tabs
          badges={[pendingFeedbacksTotal, processedFeedbacksTotal]}
          className="feedbacks-tabs"
          selectedTab={tabs.indexOf(selectedTab)}
          tabs={tabs}
          onTabSelect={setSelectedTab}
        />

        <Loader fullScreen spinning={isLoading}>
          {selectedTab === tabs[0] && (
            <div className="feedback">
              <div className="feedback-list">
                <div className="feedback-list-header">
                  <div className="feedback-list-title">{t('feedback.pending')}</div>
                  {pendingFeedbacks.length > 0 ? (
                    <div className="feedback-list-export">
                      <Button
                        className="btn-export"
                        disabled={fileLoading}
                        icon={<Download />}
                        text={t('common.btnExportData')}
                        variant="primary"
                        onClick={() => getFeedbacksExport({ reviewed: false })}
                      />
                    </div>
                  ) : null}
                </div>

                {pendingFeedbacks.length > 0 ? (
                  pendingFeedbacks.map((item: IFeedbacksList) => (
                    <Feedback
                      color="grey"
                      item={item}
                      key={item.id}
                      showRating
                      updateFeedbackStatus={updateFeedbackStatus}
                    />
                  ))
                ) : (
                  <div className="feedback-list-empty">{t('common.emptyList')} ...</div>
                )}
              </div>

              <div className="row">
                {pendingFeedbacksTotal > TABLE_ITEMS && (
                  <Pagination
                    className="pagination"
                    selectedPage={pendingFeedbacksPage}
                    showJumper
                    showPageSize
                    totalPages={pendingFeedbacksTotal}
                    variant="dark"
                    onPageChange={setPendingFeedbacksPage}
                    onPageSizeChange={setPendingFeedbacksPageSize}
                  />
                )}
              </div>
            </div>
          )}
          {selectedTab === tabs[1] && (
            <div className="feedback">
              <div className="feedback-list">
                <div className="feedback-list-header">
                  <div className="feedback-list-title">{t('feedback.reviewed')}</div>
                  {processedFeedbacks.length > 0 ? (
                    <div className="feedback-list-export">
                      <Button
                        className="btn-export"
                        disabled={fileLoading}
                        icon={<Download />}
                        text={t('common.btnExportData')}
                        variant="primary"
                        onClick={() => getFeedbacksExport({ reviewed: true })}
                      />
                    </div>
                  ) : null}
                </div>

                {processedFeedbacks.length > 0 ? (
                  processedFeedbacks.map((item: IFeedbacksList) => (
                    <Feedback
                      color={getColorFromRate(item.rate)}
                      item={item}
                      key={item.id}
                      showRating
                      updateFeedbackStatus={updateFeedbackStatus}
                    />
                  ))
                ) : (
                  <div className="feedback-list-empty">{t('common.emptyList')} ...</div>
                )}
              </div>

              <div className="row">
                {processedFeedbacksTotal > TABLE_ITEMS && (
                  <Pagination
                    className="pagination"
                    selectedPage={processedFeedbacksPage}
                    showJumper
                    showPageSize
                    totalPages={processedFeedbacksTotal}
                    variant="dark"
                    onPageChange={setProcessedFeedbacksPage}
                    onPageSizeChange={setProcessedFeedbacksPageSize}
                  />
                )}
              </div>
            </div>
          )}
        </Loader>
      </section>
    </section>
  );
};

export default FeedbacksPage;
