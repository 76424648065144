/* istanbul ignore file */
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, isBefore, isEqual, parse } from 'date-fns';
import cn from 'classnames';
import * as yup from 'yup';

import { Button, Field, Form, GoogleMapLink, Modal } from '@components';
import {
  AcceptStatus as Status,
  BookingRouteReject,
  BookingState,
  BookingUpdate,
  PlAcceptDrive,
  PlDrive,
  PlDriver,
  PlResource,
  PlVehicle,
  TransferType,
  VerifiedShuttleType,
} from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import {
  checkDriveIsRideShared,
  checkShuttleConflictRange,
  checkShuttleType,
  getFullDate,
} from '@common/utils';
import AcceptDriveFieldset from './AcceptDriveFieldset';
import { DriveFormSchema, DriveFormType } from '../../../schema/DriveForm.schema';
import { checkConflictOnAccept } from '../../../utils';
import '../BookingPanel.styles.scss';

interface AcceptDriveFormProps {
  acceptDrives: PlAcceptDrive[];
  drives: PlDrive[];
  driveIdx: number;
  drivers: PlDriver[];
  rejectBooking: (payload: BookingUpdate) => Promise<unknown>;
  rejectRoute: (payload: BookingRouteReject) => void;
  resource?: [PlResource | null, Dispatch<SetStateAction<PlResource | null>>];
  setAcceptDrives: Dispatch<SetStateAction<PlAcceptDrive[]>>;
  setDate: Dispatch<SetStateAction<Date | null>>;
  showShuttle: boolean;
  vehicles: PlVehicle[];
}

const AcceptDriveForm = ({
  acceptDrives,
  drives,
  driveIdx,
  drivers,
  rejectBooking,
  rejectRoute,
  resource,
  setAcceptDrives,
  setDate,
  showShuttle,
  vehicles,
}: AcceptDriveFormProps) => {
  const { t } = useTranslation();

  const [acceptState, setAcceptState] = useState<Status>(Status.Init);
  const [isRideSharedConfirm, setRideSharedConfirm] = useState<boolean>(false);
  const [verifiedShuttle, setVerifiedShuttle] = useState<VerifiedShuttleType | null>(null);

  const data = acceptDrives[driveIdx];
  const isDisabled = acceptState !== Status.Init;
  const isRejected = data?.rejected;
  const isRejecting = acceptState === Status.BookingReject || acceptState === Status.RouteReject;

  const checkConflicts = (formState: DriveFormType) => {
    const { driver, dropoffDate, dropoffTime, pickupDate, pickupTime, vehicle } = formState;

    const conflict = checkConflictOnAccept({
      driver,
      drives,
      dropoffDate,
      dropoffTime,
      pickupDate,
      pickupTime,
      vehicle,
      ...(showShuttle && { transferType: data.transferType }),
    });

    return conflict;
  };

  const onBackHandler = async () => {
    setRideSharedConfirm(false);
    setVerifiedShuttle(null);
    setAcceptState(Status.Init);
    setAcceptDrives((prevDrive: PlAcceptDrive[]) => {
      const idx = prevDrive.findIndex((drive) => drive.id === acceptDrives[driveIdx].id);
      const currentDrive = prevDrive[idx];
      const newDriveData = [...prevDrive];

      newDriveData[idx] = { ...currentDrive, isHide: true };

      return newDriveData;
    });
  };

  const onConfirmAccepting = async () => {
    setAcceptState(Status.Accepted);
    setAcceptDrives((prevDrive: PlAcceptDrive[]) => {
      const idx = prevDrive.findIndex((drive) => drive.id === acceptDrives[driveIdx].id);
      const currentDrive = prevDrive[idx];
      const newDriveData = [...prevDrive];

      newDriveData[idx] = {
        ...currentDrive,
        ...(verifiedShuttle ? { transferType: verifiedShuttle.transferType } : {}),
        ...(verifiedShuttle?.scheduledRouteId
          ? { scheduledRouteId: verifiedShuttle.scheduledRouteId }
          : {}),
        accepted: true,
      };

      return newDriveData;
    });
    setRideSharedConfirm(false);
    setVerifiedShuttle(null);
  };

  const handleRouteReject = async (payload: BookingRouteReject) => {
    setAcceptState(Status.RouteRejected);
    setAcceptDrives((prevDrive: PlAcceptDrive[]) => {
      const idx = prevDrive.findIndex((drive) => drive.id === acceptDrives[driveIdx].id);
      const currentDrive = prevDrive[idx];
      const newDriveData = [...prevDrive];

      newDriveData[idx] = { ...currentDrive, rejected: true };

      return newDriveData;
    });

    rejectRoute(payload);
  };

  const handleFormSubmit = useCallback(
    async (formState: DriveFormType) => {
      if (acceptState === Status.Accepting) {
        const { transferType } = data;
        const isRideShared = checkDriveIsRideShared({ data, drives });

        const verified = checkShuttleType({
          drives,
          end: parse(
            `${data.dropoffDate} ${data.dropoffTime}`,
            `${DF.ApiDate} ${DF.ApiTime}`,
            new Date(),
          ),
          start: parse(
            `${data.pickupDate} ${data.pickupTime}`,
            `${DF.ApiDate} ${DF.ApiTime}`,
            new Date(),
          ),
          transferType: data?.transferType,
          vehicle: data.vehicleId!,
        });

        if (showShuttle && verified && verified?.transferType !== data.transferType) {
          return setVerifiedShuttle(verified);
        }

        if (
          isRideShared &&
          (!showShuttle || (showShuttle && transferType !== TransferType.Shuttle))
        ) {
          setRideSharedConfirm(true);
        } else {
          const conflict = checkConflicts(formState);

          if (conflict) {
            onBackHandler();

            return toast.error(
              `${t('bookingDetails.selected')} ${conflict} ${t('bookingDetails.msgConflictMulti')}`,
            );
          }

          onConfirmAccepting();
        }

        return;
      }

      const conflict = checkConflicts(formState);

      if (conflict) {
        return toast.error(
          `${t('bookingDetails.selected')} ${conflict} ${t('bookingDetails.msgConflictMulti')}`,
        );
      }

      const {
        commentDriver,
        commentPax,
        driver,
        dropoffDate,
        dropoffTime,
        pickupDate,
        pickupTime,
        vehicle,
      } = formState;

      const pickupDateTime = getFullDate(pickupDate, pickupTime);
      const dropoffDateTime = getFullDate(dropoffDate, dropoffTime);

      if (!isBefore(pickupDateTime, dropoffDateTime) || isEqual(pickupDateTime, dropoffDateTime)) {
        return toast.error(t('bookingDetails.msgInvalidTime'));
      }

      if (showShuttle && data.transferType) {
        const shuttleConflict = checkShuttleConflictRange({
          driver,
          drives,
          end: dropoffDateTime,
          start: pickupDateTime,
          vehicle,
        });

        if (shuttleConflict === 'driver')
          return toast.error(t('planner.warnShuttleDriverConflict'));
        if (shuttleConflict === 'time') return toast.error(t('planner.warnShuttleConflict'));
      }

      setAcceptDrives((prevDrive: PlAcceptDrive[]) => {
        const idx = prevDrive.findIndex((drive) => drive.id === acceptDrives[driveIdx].id);
        const currentDrive = prevDrive[idx];
        const currentDriver = drivers.find(({ id }) => id === driver);
        const newDriveData = [...prevDrive];

        newDriveData[idx] = {
          ...currentDrive,
          commentDriver,
          commentPax,
          driverAgency: currentDriver?.driverAgency || '',
          driverEmail: currentDriver?.email || '',
          driverFirstName: currentDriver?.name || '',
          driverId: driver,
          driverLastName: currentDriver?.lastName || '',
          driverPhone: currentDriver?.phoneNumber || '',
          dropoffDate: format(dropoffDate, DF.ApiDate),
          dropoffTime: format(dropoffTime, DF.ApiTime),
          isHide: false,
          pickupDate: format(pickupDate, DF.ApiDate),
          pickupTime: format(pickupTime, DF.ApiTime),
          vehicleId: vehicle,
        };

        return newDriveData;
      });

      setAcceptState(Status.Accepting);
      setDate(pickupDate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [acceptState, data, driveIdx, drivers, drives, showShuttle],
  );

  const schema = useMemo(() => DriveFormSchema(), []);

  const initFormData = useMemo(
    () => ({
      commentDriver: '',
      commentPax: '',
      driver: '',
      dropoffDate: parse(data?.dropoffDate, DF.ApiDate, new Date()),
      dropoffTime: undefined,
      pickupDate: parse(data?.pickupDate, DF.ApiDate, new Date()),
      pickupTime: parse(data?.pickupTime, DF.ApiTime, new Date()),
      vehicle: '',
    }),
    [data],
  );

  return (
    <>
      <Form
        className={cn('bp-leg-form', {
          'bp-leg-form-rejected': isRejected,
          'bp-leg-form-rejecting': isRejecting,
        })}
        defaultValues={initFormData}
        schema={schema}
        theme="dark"
        onSubmit={handleFormSubmit}
      >
        {({ setValue, watch }) => (
          <>
            {acceptState !== Status.BookingReject && (
              <Button
                className="btn btn-booking-reject"
                text={t('planner.btnReject')}
                variant="transparent"
                onClick={() => setAcceptState(Status.BookingReject)}
              />
            )}

            <AcceptDriveFieldset
              acceptDrives={acceptDrives}
              disabled={isDisabled}
              drivers={drivers}
              leg={data.routeNumber}
              resource={resource}
              vehicles={vehicles}
              setValue={setValue}
              watch={watch}
            />

            {acceptState !== Status.BookingReject && acceptState !== Status.RouteReject && (
              <div className="bp-leg-form-ctrl">
                {!isRejected && acceptState === Status.Init && (
                  <div className="bp-leg-form-buttons">
                    <Button
                      className="btn btn-leg-reject"
                      text={t('planner.btnRejectRoute')}
                      variant="warning"
                      onClick={() => setAcceptState(Status.RouteReject)}
                    />

                    <Button
                      className="btn btn-submit"
                      text={t('bookingDetails.btnShowPreview')}
                      type="submit"
                      variant="submit"
                    />

                    <GoogleMapLink
                      origins={
                        data.pickupLatitude && data.pickupLongitude
                          ? { lat: data.pickupLatitude, lng: data.pickupLongitude }
                          : null
                      }
                      destinations={
                        data.dropoffLatitude && data.dropoffLongitude
                          ? { lat: data.dropoffLatitude, lng: data.dropoffLongitude }
                          : null
                      }
                    />
                  </div>
                )}

                {!isRejected && acceptState === Status.Accepting && (
                  <div className="bp-leg-form-buttons">
                    <Button
                      className="btn btn-submit"
                      text={t('bookingDetails.btnConfirmAccept')}
                      type="submit"
                      variant="submit"
                    />
                    <Button
                      className="btn"
                      text={t('common.btnBack')}
                      variant="transparent"
                      onClick={onBackHandler}
                    />
                  </div>
                )}

                {isRejected && <div className="bp-leg-form-rejected-status">Rejected leg</div>}
              </div>
            )}
          </>
        )}
      </Form>

      {(acceptState === Status.BookingReject || acceptState === Status.RouteReject) && (
        <Form
          className="bp-leg-form-reject"
          defaultValues={{ reason: '' }}
          schema={yup.object().shape({ reason: yup.string().optional() })}
          theme="dark"
          onSubmit={({ reason }) =>
            acceptState === Status.BookingReject
              ? rejectBooking({ rejectComment: reason, state: BookingState.Rejected })
              : handleRouteReject({
                  agencyId: data?.agencyId!,
                  bookingId: data?.bookingId,
                  rejectComment: reason,
                  route: data?.routeNumber,
                })
          }
        >
          <h3>
            {acceptState === Status.BookingReject
              ? t('planner.titleReject')
              : t('planner.titleRejectRoute')}
          </h3>

          <Field
            name="reason"
            input="textarea"
            className="field-reason"
            placeholder="Rejection reason"
            variant="unstyled"
            data-testid="planner-drive-form-field-reject-reason"
          />

          <footer>
            <Button
              className="btn btn-cancel"
              text={t('common.btnCancel')}
              variant="outline"
              onClick={() => setAcceptState(Status.Init)}
            />

            <Button
              className="btn btn-confirm"
              text={t('common.btnConfirm')}
              variant="filled"
              type="submit"
            />
          </footer>
        </Form>
      )}

      <Modal
        className="modal-planner-confirm"
        showBtnClose
        title={t('planner.createDrive')}
        variant="confirm"
        visible={isRideSharedConfirm || !!verifiedShuttle}
        onClose={onBackHandler}
        onConfirm={onConfirmAccepting}
      >
        {isRideSharedConfirm && (
          <>
            <p>{t('planner.multiWarn1')}.</p>
            <p>
              {t('common.click')} <b>{t('common.yes')}</b> {t('planner.multiWarn2')} <br />
              {t('common.click')} <b>{t('common.no')}</b> {t('planner.multiWarn3')}.
            </p>
          </>
        )}

        {verifiedShuttle && (
          <p>
            {t('planner.warnShuttleChange')}{' '}
            {verifiedShuttle.transferType
              .split('_')
              .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' ')}
          </p>
        )}
      </Modal>
    </>
  );
};

export default AcceptDriveForm;
