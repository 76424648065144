import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addMinutes, isSameDay } from 'date-fns';
import { TIME_INTERVAL } from '@common/constants';
import { DateInputFormat, GoogleSuggestion } from '@common/types';
import { InputDatePicker, InputLocation, InputText, InputTimePicker } from '@components';

type SectionProps = {
  city?: string;
  country?: string;
  debugging?: boolean;
  form: UseFormReturn<any>;
  showGoogleLocation?: boolean;
};

const AirportSection = ({ city, country, debugging, form, showGoogleLocation }: SectionProps) => {
  const { t } = useTranslation();
  const { control: ctrl, setValue, watch } = form;
  const isDropoffLocExtraRequired = watch('routes.0.dropoffEqualFacility');
  const isPickupLocExtraRequired = watch('routes.0.pickupEqualFacility');
  const routePickupDate = watch('routes.0.pickupDate');
  const isSameDate = isSameDay(routePickupDate, new Date());

  const handleLocationChange = (name: string) => (e: GoogleSuggestion | string) => {
    if (typeof e === 'object') {
      setValue?.(`${name}EqualFacility`, e.dataset?.isFacilityLocation);
      setValue?.(`${name}Town`, e.dataset?.town || city);
      setValue?.(`${name}Lat`, e.dataset?.lat ?? 0);
      setValue?.(`${name}Lng`, e.dataset?.lng ?? 0);
    }
  };

  return (
    <fieldset>
      <InputDatePicker
        name="routes.0.pickupDate"
        className="field"
        control={ctrl}
        hideOutsideDates
        label={t('bookingDetails.pickupDate')}
        minDate={new Date()}
        required
        valueFormat={DateInputFormat.DateByDots}
        data-testid="bf-pickup-date"
      />
      <InputTimePicker
        name="routes.0.pickupTime"
        className="field"
        control={ctrl}
        label={t('bookingDetails.pickupTime')}
        minTime={isSameDate ? addMinutes(new Date(), TIME_INTERVAL) : undefined}
        required
        testId="bf-pickup-time"
      />
      {showGoogleLocation ? (
        <>
          <InputText name="routes.0.pickupTown" control={ctrl} hidden />
          <InputText name="routes.0.dropoffTown" control={ctrl} hidden />
          <InputLocation
            name="routes.0.pickupLocation"
            className="field"
            control={ctrl}
            debugging={debugging}
            defaultCity={city}
            defaultCountry={country}
            label={`${t('bookingDetails.pickupAddress')} (Google Maps)`}
            required
            onChange={handleLocationChange('routes.0.pickup')}
            data-testid="bf-pickup-location"
          />
          <InputLocation
            name="routes.0.dropoffLocation"
            className="field"
            control={ctrl}
            debugging={debugging}
            defaultCity={city}
            defaultCountry={country}
            label={`${t('bookingDetails.dropoffAddress')} (Google Maps)`}
            required
            onChange={handleLocationChange('routes.0.dropoff')}
            data-testid="bf-dropoff-location"
          />
          <InputText
            name="routes.0.pickupLocExtra"
            className="field"
            control={ctrl}
            description={isPickupLocExtraRequired ? t('bookingForm.tooltipAddress') : ''}
            label={`${t('bookingDetails.pickupAddress')} (${t('common.manualInput')}${
              isPickupLocExtraRequired ? '' : `, ${t('common.optional')}`
            })`}
            required={isPickupLocExtraRequired}
          />
          <InputText
            name="routes.0.dropoffLocExtra"
            className="field"
            control={ctrl}
            description={isDropoffLocExtraRequired ? t('bookingForm.tooltipAddress') : ''}
            label={`${t('bookingDetails.dropoffAddress')} (${t('common.manualInput')}${
              isDropoffLocExtraRequired ? '' : `, ${t('common.optional')}`
            })`}
            required={isDropoffLocExtraRequired}
          />
        </>
      ) : (
        <>
          <InputText
            name="routes.0.pickupTown"
            className="field"
            control={ctrl}
            label={t('bookingDetails.pickupTown')}
            required
            data-testid="bf-pickup-town"
          />
          <InputText
            name="routes.0.pickupLocation"
            className="field"
            control={ctrl}
            label={t('bookingDetails.pickupLocation')}
            required
            data-testid="bf-pickup-location"
          />
          <InputText
            name="routes.0.dropoffTown"
            className="field"
            control={ctrl}
            label={t('bookingDetails.dropoffTown')}
            required
            data-testid="bf-dropoff-town"
          />
          <InputText
            name="routes.0.dropoffLocation"
            className="field"
            control={ctrl}
            label={t('bookingDetails.dropoffLocation')}
            required
            data-testid="bf-dropoff-location"
          />
        </>
      )}
      <InputTimePicker
        name="bookingExtra.flightArrivalDepartureTime"
        className="field"
        control={ctrl}
        label={t('common.dropoffTimeAirport')}
        required
        testId="bf-flight-time"
      />
      <InputText
        name="bookingExtra.flightNumber"
        className="field"
        control={ctrl}
        label={t('common.flightNumber')}
        required
        data-testid="bf-flight-number"
      />
      <InputText
        name="bookingExtra.remarks"
        className="field wide"
        control={ctrl}
        label={`${t('common.remarks')} (${t('common.optional')})`}
        data-testid="bf-remarks"
      />
    </fieldset>
  );
};

export default AirportSection;
