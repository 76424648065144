import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Table } from '@unbooking/ui-modules';

import { Download } from '@assets/svg/icons';
import { CURRENT_MONTH, CURRENT_YEAR, MONTHS, YEARS } from '@common/constants';
import { CountryReport, ReportParams } from '@common/interfaces';
import { useFacility } from '@common/hooks';
import { DateFormat as DF } from '@common/types';
import { Serializer } from '@common/utils';
import { Button, Field, Form, PageTitle } from '@components';
import { useRepository } from '@context';
import { CountryReportSchema } from '@schemas';
import { getReportColumns } from './utils';
import './CountryReportPage.styles.scss';

type FormState = { month: string; year: string };

const INIT_FORM_DATA: FormState = {
  month: CURRENT_MONTH.value,
  year: CURRENT_YEAR.value,
};

const CountryReportPage: FC = () => {
  const { t } = useTranslation();
  const { reportRepo } = useRepository();
  const { agencyName, facility, facilityId: fid } = useFacility();
  const { agencyId, city, country } = facility;

  const [reportData, setReportData] = useState<CountryReport[]>([]);
  const [filters, setFilters] = useState({ month: CURRENT_MONTH.value, year: CURRENT_YEAR.value });

  const { data, isLoading, refetch } = useQuery(
    ['get-country-report', filters],
    () => reportRepo.getCountryReport(fid, { agencyId, ...filters }),
    { enabled: !!agencyId },
  );

  const { mutate: createCountryReport, isLoading: isCountryReportProcessing } = useMutation(
    'create-country-report',
    (date: string) => reportRepo.createCountryReport(fid, date),
    {
      onSuccess: refetch,
      onError: () => {
        toast.error(t('common.errorMsgDefault'));
      },
    },
  );

  const { mutate: exportCountryReport, isLoading: isExportProcessing } = useMutation(
    'export-country-report',
    (params: ReportParams) => reportRepo.exportCountryReport(fid, params),
    {
      onError: () => {
        toast.error(t('common.msgErrorExport'));
      },
    },
  );

  useEffect(() => {
    if (!data) return;

    const formattedData = data.map(Serializer.formatCountryReport);

    if (formattedData.length) {
      const totals = formattedData.reduce((acc: Record<string, number>, item: CountryReport) => {
        Object.keys(item).forEach((key) => {
          const value = item[key as keyof CountryReport];
          if (typeof value === 'number') acc[key] = (acc[key] || 0) + value;
        });
        return acc;
      }, {} as Record<string, number>);

      const roundedTotals = Object.fromEntries(
        Object.entries(totals).map(([key, value]) => [key, Number(value).toFixed(2)]),
      );

      const totalRow = { ...roundedTotals, locationName: 'Country Total' };
      const tableData = [totalRow, ...formattedData].map((item, idx) => ({ ...item, id: idx }));
      setReportData(tableData);
    }
  }, [data]);

  const handleCreateReport = () =>
    createCountryReport(format(new Date(+filters.year, +filters.month - 1, 1), DF.ApiDate));

  const handleFilterChange = (field: keyof typeof filters, value: string) =>
    setFilters((current) => ({ ...current, [field]: value }));

  const handleSubmit = useCallback(
    async ({ month, year }: FormState) => exportCountryReport({ agencyId, month, year }),
    [agencyId, exportCountryReport],
  );

  const isCurrentYear = useMemo(
    () => YEARS.slice(0, 1).some((year) => year.value === filters.year),
    [filters.year],
  );

  const schema = useMemo(() => CountryReportSchema(), []);

  return (
    <section className="hbh-container country-report">
      <PageTitle
        title={`${city}, ${country} ${t('report.countryReporting')} | ${agencyName}`}
        bottomLine
        tools={
          <Button
            className="btn btn-export"
            disabled={isExportProcessing}
            form="report_filters_form"
            leftIcon={<Download />}
            text={t('common.btnExportData')}
            type="submit"
            variant="submit"
          />
        }
      />

      <div className="country-report-container">
        <div className="row">
          <Form
            className="country-report-form"
            defaultValues={INIT_FORM_DATA}
            id="report_filters_form"
            schema={schema}
            onSubmit={handleSubmit}
          >
            <div className="filters">
              <Field
                name="month"
                input="dropdown"
                className="month"
                id="cost_recovery_report_month_dropdown"
                label={t('common.month')}
                options={MONTHS}
                onChange={(value: string) => handleFilterChange('month', value)}
                data-testid="report-filter-month"
              />

              <Field
                name="year"
                input="dropdown"
                className="year"
                id="cost_recovery_report_year_dropdown"
                label={t('common.year')}
                options={YEARS}
                onChange={(value: string) => handleFilterChange('year', value)}
                data-testid="report-filter-year"
              />

              {isCurrentYear && (
                <Button
                  className="btn btn-calc-report"
                  text={t('common.btnCalculateNow')}
                  onClick={handleCreateReport}
                />
              )}
            </div>
          </Form>
        </div>

        <div className="country-report-table">
          <Table
            columns={getReportColumns(t)}
            data={reportData}
            isLoading={isLoading || isCountryReportProcessing}
            variant="light"
          />
        </div>
      </div>
    </section>
  );
};

export default CountryReportPage;
