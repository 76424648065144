/* istanbul ignore file */
import { t } from 'i18next';
import * as yup from 'yup';

const schema = {
  commentDriver: yup.string().optional(),
  commentPax: yup.string().optional(),
  driver: yup.string().required(t('common.fieldRequired')),
  dropoffDate: yup.date().required(t('common.fieldRequired')),
  dropoffTime: yup.date().required(t('common.fieldRequired')),
  pickupDate: yup.date().required(t('common.fieldRequired')),
  pickupTime: yup.date().required(t('common.fieldRequired')),
  vehicle: yup.string().required(t('common.fieldRequired')),
};

const driveSchema = yup.object(schema);

const arrayDriveSchema = yup.object({
  drives: yup.array().of(driveSchema).required(t('common.fieldRequired')),
});

type DriveFormType = yup.InferType<typeof driveSchema>;
type DriveEditFormType = yup.InferType<typeof arrayDriveSchema>;

export const DriveFormSchema = () => driveSchema;
export const DriveEditFormSchema = () => arrayDriveSchema;

export type { DriveFormType, DriveEditFormType };
