import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { DatesRangeValue } from '@mantine/dates';
import { Button, DateInputPicker } from '@components';
import { Download } from '@assets/svg/icons';
import './ExportRange.styles.scss';

type ExportRangeProps = {
  dateFormat?: string;
  onClose: () => void;
  onSubmit: (data: { start: string; end: string }) => void;
};

const ExportRange: FC<ExportRangeProps> = ({ dateFormat = 'yyyy-MM-dd', onClose, onSubmit }) => {
  const { t } = useTranslation();
  const today = new Date();

  const [datePeriod, setDatePeriod] = useState<DatesRangeValue>([
    startOfMonth(today),
    endOfMonth(today),
  ]);

  const handleRangeSubmit = () => {
    const [start, end] = datePeriod;

    if (start && end) {
      onSubmit({
        start: format(start, dateFormat),
        end: format(end, dateFormat),
      });
      onClose?.();
    }
  };

  return (
    <div className="export-range">
      <DateInputPicker
        className="date-input-picker"
        data-testid="export-range-picker"
        format="DD MMM YYYY"
        iconPosition="left"
        placeholder={t('common.inputSelectDays')}
        type="range"
        variant="dark"
        value={datePeriod}
        onChange={(v) => setDatePeriod(v as DatesRangeValue)}
      />

      <div className="footer">
        <Button className="btn" text={t('common.btnCancel')} variant="outline" onClick={onClose} />
        <Button
          className="btn"
          leftIcon={<Download />}
          text={t('common.btnExport')}
          variant="filled"
          onClick={handleRangeSubmit}
        />
      </div>
    </div>
  );
};

export default ExportRange;
