/* istanbul ignore file */
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from '@assets/svg/icons';
import { BookingDetails } from '@common/interfaces';
import { Button } from '@components';
import '../BookingPanel.styles.scss';

const BookingInfo = ({ booking }: { booking: BookingDetails }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { agencyFk, firstName, lastName, pax } = booking;
  const link = pathname.split('/').slice(0, -2).join('/');

  return (
    <div className="bp-info">
      <Button className="bp-info-btn-back" icon={<ArrowLeft />} link={link} linkStyle="icon" />
      <div className="bp-info-title">
        <h2>{`${booking.typeOfTripDisplay} • ${booking.transferTypeDisplay} transfer`}</h2>
        <span>{`${firstName} ${lastName}, ${agencyFk?.shortName} - ${pax} Pax`}</span>
      </div>
      <Button
        className="bp-info-details"
        link={`${link}/${booking.id}`}
        text={t('bookingDetails.details')}
        linkStyle="transparent"
      />
    </div>
  );
};

export default BookingInfo;
