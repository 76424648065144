/* istanbul ignore file */
import type { DropdownOption, PlDriver, PlVehicle } from '@common/interfaces';

const DEFAULT_MAX_PASSENGER_CAPACITY = 4;

export const findOption = (
  options?: DropdownOption[],
  value?: string,
): DropdownOption | undefined => options?.find((option) => option.value === value);

export const getDefaultOption = (option?: DropdownOption, spare = ''): DropdownOption =>
  option || { label: spare, value: spare };

export const mapDriverOptions = (drivers: PlDriver[]): DropdownOption[] =>
  drivers.map(({ id, lastName, name }) => ({ label: `${name} ${lastName}`, value: id }));

export const mapPaxOptions = (
  vehicles: PlVehicle[],
  vehicleId: string,
  pax?: number,
): DropdownOption[] => {
  const currentVehicle = vehicles?.find((v: PlVehicle) => v.id === vehicleId);
  const vehicleMaxCapacity = currentVehicle?.maxCapacity || DEFAULT_MAX_PASSENGER_CAPACITY;

  const options = Array.from({ length: vehicleMaxCapacity }, (_, idx) => ({
    label: String(idx + 1),
    value: String(idx + 1),
  }));

  if (pax && pax > vehicleMaxCapacity) {
    options.push({ label: String(pax), value: String(pax) });
  }

  return options;
};

export const mapLocationOptions = (data: string[] = []): DropdownOption[] =>
  data.map((v: string) => ({ label: v, value: v }));

export const mapVehicleOptions = (vehicles: PlVehicle[]): DropdownOption[] =>
  vehicles.map(({ id, title }) => ({ label: title, value: id }));

export const weekdays = [
  { value: '0', label: 'Mon' },
  { value: '1', label: 'Tue' },
  { value: '2', label: 'Wed' },
  { value: '3', label: 'Thu' },
  { value: '4', label: 'Fri' },
  { value: '5', label: 'Sat' },
  { value: '6', label: 'Sun' },
];
