/* eslint-disable no-nested-ternary */
import { type FC, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import type { AxiosError } from 'axios';
import type { DatesRangeValue } from '@mantine/dates';
import { format, isDate } from 'date-fns';
import { TextInput } from '@mantine/core';

import { Button, Pagination, Table } from '@unbooking/ui-modules';
import {
  ApiList,
  LBAuxFilters,
  LBDriver,
  LBDrives,
  LBDrivesApi,
  LBVehicle,
  SortOrder,
  SummaryType,
} from '@common/interfaces';
import { getAgencyLogo, Serializer } from '@common/utils';
import { useFacility, useToggleBoolean } from '@common/hooks';
import { useRepository } from '@context';
import {
  ClientSignature,
  DateInputPicker,
  FiltersBlock,
  PageTitle,
  Tabs,
  Tooltip,
} from '@components';
import { useHBHLocation, useLogbookFilters } from '@common/hooks/logbook';
import { BalanceWallet, Download, Manual, Pencil } from '@assets/svg/icons';
import {
  Carpool,
  ChevronDown,
  ChevronRight,
  Clock,
  Device,
  VehicleIcon,
} from '@assets/svg/icons/logbook';
import './MobilityLogbookPage.styles.scss';
import { useDebounce } from 'usehooks-ts';

const initSearch = { budgetCode: '', refCode: '' };

const MobilityLogbookPage: FC = () => {
  const {
    agencyName,
    facility: { agencyId, city, country, sharedWithAgencies },
    facilityId,
  } = useFacility();
  const {
    period,
    isFilterLoading,
    logbookActiveFilters,
    logbookFilters,
    selectedFilters,
    logbookFilterDispatch,
    setPeriod,
  } = useLogbookFilters();
  const { Logo } = useHBHLocation();
  const { logbookRepository } = useRepository();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isFacilityMultiAgency = !!sharedWithAgencies;
  const tabs = useMemo(() => ['DONE drives', 'To DO drives'], []);

  const [isFilterOpened, toggleFilters] = useToggleBoolean();

  const [auxFilters, setAuxFilters] = useState<LBAuxFilters>(logbookActiveFilters);
  const [range, setRange] = useState<[Date | null, Date | null]>(period);
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [search, setSearch] = useState(initSearch);

  const [drives, setDrives] = useState<LBDrives[]>([]);
  const [columnOrder, setColumnOrder] = useState<string>('-driveTime');
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [drivesTodo, setDrivesTodo] = useState<LBDrives[]>([]);
  const [columnOrderDrivesTodo, setColumnOrderDrivesTodo] = useState<string>('-driveTime');
  const [pageSizeDrivesTodo, setPageSizeDrivesTodo] = useState<number>(10);
  const [selectedPageDrivesTodo, setSelectedPageDrivesTodo] = useState<number>(1);
  const [totalItemsDrivesTodo, setTotalItemsDrivesTodo] = useState<number>(0);

  const debBudgetCode = useDebounce(search.budgetCode, 900);
  const debRefCode = useDebounce(search.refCode, 900);

  const fetchDrives = async (isTodo = false) => {
    const data = await logbookRepository.getDrives(facilityId, {
      dateEnd: range[1] ? format(range[1], 'yyyy-MM-dd') : undefined,
      dateStart: range[0] ? format(range[0], 'yyyy-MM-dd') : undefined,
      isMultiAgency: isFacilityMultiAgency,
      isTodoDrives: isTodo,
      ordering: isTodo ? columnOrderDrivesTodo : columnOrder,
      page: isTodo ? selectedPageDrivesTodo : selectedPage,
      pageSize: isTodo ? pageSizeDrivesTodo : pageSize,
      auxFilters,
      budgetCode: debBudgetCode,
      refCode: debRefCode,
    });

    return data;
  };

  const { isFetching: isDrivesFetching, isLoading: isDrivesLoading } = useQuery(
    [
      'get-drives',
      range,
      columnOrder,
      pageSize,
      selectedPage,
      auxFilters,
      debBudgetCode,
      debRefCode,
    ],
    () => fetchDrives(),
    {
      enabled: Boolean(agencyId) && isDate(range[0]) && isDate(range[1]),
      onSuccess(data: ApiList<LBDrivesApi>) {
        setDrives(data?.results?.map(Serializer.formatLogbookDrives) || []);
        setTotalItems(data.count);
      },
      onError: () => {
        setDrives([]);
        setTotalItems(0);
      },
    },
  );

  const { isFetching: isDrivesTodoFetching, isLoading: isDrivesTodoLoading } = useQuery(
    [
      'get-drives-todo',
      range,
      columnOrderDrivesTodo,
      pageSizeDrivesTodo,
      selectedPageDrivesTodo,
      auxFilters,
      debBudgetCode,
      debRefCode,
    ],
    () => fetchDrives(true),
    {
      enabled: Boolean(agencyId) && isDate(range[0]) && isDate(range[1]),
      onSuccess(data: ApiList<LBDrivesApi>) {
        setDrivesTodo(data?.results?.map(Serializer.formatLogbookDrives) || []);
        setTotalItemsDrivesTodo(data.count);
      },
      onError: () => {
        setDrivesTodo([]);
        setTotalItemsDrivesTodo(0);
      },
    },
  );

  const { isLoading: fileLoading, refetch: getExportDrives } = useQuery<unknown, AxiosError, void>(
    'export-drives',
    () =>
      logbookRepository.getDrivesExport(facilityId, {
        dateEnd: range[1] ? format(range[1], 'yyyy-MM-dd') : undefined,
        dateStart: range[0] ? format(range[0], 'yyyy-MM-dd') : undefined,
        isMultiAgency: isFacilityMultiAgency,
        isTodoDrives: tabs.indexOf(selectedTab) === 1,
        location: `${agencyName}|${country}|${city}`,
        ordering: tabs.indexOf(selectedTab) === 0 ? columnOrder : columnOrderDrivesTodo,
        auxFilters,
        budgetCode: debBudgetCode,
        refCode: debRefCode,
      }),
    {
      enabled: false,
      refetchInterval: false,
      retry: false,
      onError: (error: any) => {
        if (error.response) toast.error(t('common.msgErrorExport'));
      },
    },
  );

  useEffect(() => {
    if (logbookActiveFilters || period) {
      setSelectedPage(1);
      setSelectedPageDrivesTodo(1);
      setAuxFilters(logbookActiveFilters);
      setRange(period);
    }
  }, [logbookActiveFilters, period]);

  const getSummaryTypeIcon = (type: number, text: string) => {
    const applyIcon = (icon: JSX.Element, tooltip: string): JSX.Element => (
      <Tooltip label={tooltip}>{icon}</Tooltip>
    );

    let icon: JSX.Element | null;

    switch (type) {
      case SummaryType.DriverManualInput:
        icon = applyIcon(<Pencil fill="#EAF2F9" />, text);
        break;
      case SummaryType.FocalPointManualInput:
        icon = applyIcon(<Manual fill="#EAF2F9" />, text);
        break;
      case SummaryType.VTS:
        icon = applyIcon(<VehicleIcon fill="#EAF2F9" />, text);
        break;
      case SummaryType.GPS:
        icon = applyIcon(<Device fill="#EAF2F9" />, text);
        break;
      default:
        icon = null;
        break;
    }

    return icon;
  };

  const getDistanceColumn = ({
    distance,
    distanceNovacom,
    summaryType,
    summaryTypeDisplay,
  }: LBDrives): JSX.Element => (
    <div data-testid="mlb-drive-distance">
      {distanceNovacom ? `${distanceNovacom} km` : distance ? `${distance} km` : `-`}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {summaryType && summaryTypeDisplay
          ? getSummaryTypeIcon(summaryType, summaryTypeDisplay)
          : null}
      </div>
    </div>
  );

  const getPickUpTimeColumn = (time: string): JSX.Element => (
    <span className="col-pickup-time ">
      <Clock />
      <span>{time}</span>
    </span>
  );

  const getVehicleColumn = (vehicle: LBVehicle): JSX.Element => (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 5 }}>{getAgencyLogo(vehicle.agency.shortName, 20)}</div>
      <p>{vehicle.label}</p>
    </div>
  );

  const handleSearchChange = (field: keyof typeof search, value: string) => {
    setSearch((s) => ({ ...s, [field]: value }));
  };

  const handleRowClick = (id: string) => {
    navigate(`details/${id}`);
  };

  const columns = [
    {
      key: 'referenceCode',
      title: 'Ref',
      ellipsis: {
        showTitle: true,
      },
      width: 80,
      render: (item: LBDrives): JSX.Element => (
        <>
          #{item.booking.referenceCode}
          {item.isCarpooled && (
            <Tooltip label="Rideshared Trip">
              <Carpool />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      dataIndex: 'driveTime',
      key: 'driveTime',
      title: 'Drive Time',
      defaultSortOrder: 'descend',
      sorter: true,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      width: 100,
    },
    {
      dataIndex: 'vehicle',
      key: 'vehicle',
      title: 'Vehicle',
      width: 140,
      render: getVehicleColumn,
    },
    {
      dataIndex: 'driver',
      key: 'driver',
      title: 'Driver',
      render: (driver: LBDriver): string =>
        driver?.firstName ? `${driver?.firstName} ${driver?.lastName}` : '—',
    },
    {
      dataIndex: 'paxName',
      key: 'paxName',
      title: 'Pax',
    },
    {
      dataIndex: 'pax',
      key: 'pax',
      title: 'Pax n°',
      width: 80,
    },
    {
      dataIndex: 'paxAgency',
      key: 'paxAgency',
      title: 'Agency',
      width: 90,
    },
    {
      dataIndex: 'pickupLocation',
      key: 'pickupLocation',
      title: 'Pick-up',
      render: (pickupLocation: string): string => pickupLocation || '—',
    },
    {
      dataIndex: 'dropoffLocation',
      key: 'dropoffLocation',
      title: 'Drop-off',
      render: (dropoffLocation: string): string => dropoffLocation || '—',
    },
  ];

  const columnsDone = [
    ...columns,
    {
      key: 'km',
      title: 'Km',
      render: getDistanceColumn,
      width: 90,
    },
    {
      dataIndex: 'duration',
      key: 'duration',
      title: 'Duration',
      render: (duration: string): string => duration || '—',
      width: 90,
    },
    {
      dataIndex: 'transferTypeRepr',
      key: 'transferType',
      title: 'Type',
      width: 80,
    },
    {
      key: 'budgetCode',
      title: 'Budget Code',
      width: 80,
      render: (item: LBDrives): JSX.Element => (
        <Tooltip label={item?.booking?.budgetCode || '—'}>
          <BalanceWallet fill="white" />
        </Tooltip>
      ),
    },
    {
      dataIndex: 'clientSignature',
      key: 'clientSignature',
      title: 'Signature',
      render: (clientSignature: string) => <ClientSignature data={clientSignature} />,
      width: 120,
    },
    {
      key: 'goTo',
      title: '',
      render: () => (
        <div className="btn btn-details">
          <ChevronRight fill="white" />
        </div>
      ),
      width: 70,
    },
  ];

  const columnsToDo = [
    ...columns,
    {
      dataIndex: 'transferTypeRepr',
      key: 'transferType',
      title: 'Type',
    },
    {
      key: 'budgetCode',
      title: 'Budget Code',
      width: 80,
      render: (item: LBDrives): JSX.Element => (
        <Tooltip label={item?.booking?.budgetCode || '—'}>
          <BalanceWallet fill="white" />
        </Tooltip>
      ),
    },
    {
      dataIndex: 'pickupTime',
      key: 'pickupTime',
      title: 'Pick-up time',
      render: getPickUpTimeColumn,
    },
    {
      key: 'goTo',
      title: '',
      render: () => (
        <div className="btn btn-details">
          <ChevronRight fill="white" />
        </div>
      ),
      width: 70,
    },
  ];

  const isFiltersLoaded =
    !isFilterLoading && logbookFilters && Boolean(Object.keys(logbookFilters).length);

  return (
    <section className="hbh-container mobility-logbook-page">
      <Tabs
        badges={[totalItems, totalItemsDrivesTodo]}
        selectedTab={tabs.indexOf(selectedTab)}
        tabs={tabs}
        toolbar={
          <DateInputPicker
            format="DD MMM YY"
            label={`${t('feedback.timePeriod')}:`}
            placeholder="Choose dates range"
            type="range"
            variant="dark"
            value={period}
            onChange={(v) => setPeriod(v as DatesRangeValue)}
          />
        }
        onTabSelect={setSelectedTab}
      />

      <div className="page-title">
        <Logo fill="#E0E8ED" width={58} height={58} />
        <PageTitle
          title={city && country ? `${city}, ${country} | ${agencyName}` : ''}
          tools={
            <>
              {isFiltersLoaded && (
                <Button
                  className="btn btn-filters"
                  disabled={isFilterLoading}
                  icon={<ChevronDown width="15px" height="15px" />}
                  text={t('logbook.btnFilters')}
                  variant="primary"
                  onClick={toggleFilters}
                />
              )}

              <Button
                className="btn btn-export"
                disabled={fileLoading}
                icon={<Download />}
                text={`${t('common.btnExportData')}`}
                variant="primary"
                onClick={getExportDrives}
              />
            </>
          }
        />
      </div>

      <FiltersBlock
        filters={logbookFilters}
        filterDispatch={logbookFilterDispatch}
        open={isFiltersLoaded && isFilterOpened}
        selected={selectedFilters}
        onClear={() => setSearch(() => initSearch)}
        aux={
          <>
            <TextInput
              name="budgetCode"
              className="field"
              label={t('common.budgetCode')}
              value={search.budgetCode}
              onChange={(e) => handleSearchChange('budgetCode', e.target.value as string)}
            />
            <TextInput
              name="refCode"
              className="field"
              label={t('common.refCode')}
              value={search.refCode}
              onChange={(e) => handleSearchChange('refCode', e.target.value as string)}
            />
          </>
        }
      />

      {selectedTab === tabs[0] ? (
        <section className="drives-list">
          <div className="table">
            <Table
              columns={columnsDone}
              data={drives}
              isLoading={isDrivesFetching || isDrivesLoading}
              variant="dark"
              onChangeColumnOrder={setColumnOrder}
              onRow={(row: LBDrives) => ({
                onClick: () => handleRowClick(row.id),
              })}
            />
          </div>

          {!isDrivesFetching && totalItems > 10 ? (
            <Pagination
              className="pagination"
              selectedPage={selectedPage}
              showJumper
              totalPages={totalItems}
              variant="dark"
              onPageChange={setSelectedPage}
              onPageSizeChange={setPageSize}
            />
          ) : null}
        </section>
      ) : null}

      {selectedTab === tabs[1] ? (
        <section className="drives-list-todo">
          <div className="table">
            <Table
              columns={columnsToDo}
              data={drivesTodo}
              isLoading={isDrivesTodoFetching || isDrivesTodoLoading}
              variant="dark"
              onChangeColumnOrder={setColumnOrderDrivesTodo}
              onRow={(row: LBDrives) => ({
                onClick: () => handleRowClick(row.id),
              })}
            />
          </div>

          {!isDrivesTodoFetching && totalItemsDrivesTodo > 10 ? (
            <Pagination
              className="pagination"
              selectedPage={selectedPageDrivesTodo}
              showJumper
              totalPages={totalItemsDrivesTodo}
              variant="dark"
              onPageChange={setSelectedPageDrivesTodo}
              onPageSizeChange={setPageSizeDrivesTodo}
            />
          ) : null}
        </section>
      ) : null}
    </section>
  );
};

export default MobilityLogbookPage;
