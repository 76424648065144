import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect } from 'react';
import type { DatesRangeValue } from '@mantine/dates';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { FilterActionTypes } from '@common/constants';
import { DropdownOptionExt, FilterParams, FilterSelected, TransferType } from '@common/interfaces';
import { Button, DateInputPicker, Dropdown, Tag } from '@components';
import { CloseRound } from '@assets/svg/icons';
import { useBookingFilters } from '../../hooks';
import { FilterDict, FilterIcon, FilterLabel, getTransferIcon } from '../../utils';
import './Filters.styles.scss';

type FiltersProps = {
  selectedTab: number;
  setActiveFilters?: (_: FilterParams) => void;
  setActivePeriod: Dispatch<SetStateAction<DatesRangeValue>>;
};

const Filters: FC<FiltersProps> = ({ selectedTab, setActiveFilters, setActivePeriod }) => {
  const { t } = useTranslation();
  const { activeFilters, filters, isFilterLoading, period, selected, filterDispatch, setPeriod } =
    useBookingFilters();

  const handleFilterChange = (title: string, option: DropdownOptionExt) => {
    filterDispatch?.({ type: FilterActionTypes.CHANGE_FILTER, payload: { title, option } });
  };

  const handleCancel = (title: string) => {
    filterDispatch?.({ type: FilterActionTypes.CLEAR_ONE, payload: title });
  };

  const handleClear = () => {
    filterDispatch?.({ type: FilterActionTypes.CLEAR_ALL });
    setPeriod([null, null]);
  };

  useEffect(() => {
    if (selectedTab === 0) {
      filterDispatch?.({ type: FilterActionTypes.CLEAR_ONE, payload: FilterDict.BookingStates });
    }
  }, [selectedTab, filterDispatch]);

  useEffect(() => {
    setActiveFilters?.(activeFilters);
  }, [activeFilters, setActiveFilters]);

  useEffect(() => {
    setActivePeriod?.(period);
  }, [period, setActivePeriod]);

  return (
    <section className="filter-bar">
      <section className="filter-bar-container">
        <h2>{t('common.filters')}: </h2>
        <DateInputPicker
          allowSingleDateInRange
          className="date-input-picker"
          format="DD.MM.YYYY"
          iconPosition="left"
          placeholder={t('common.inputSelectDays')}
          type="range"
          variant="dark"
          value={period}
          onChange={(v) => setPeriod(v as DatesRangeValue)}
        />

        {filters?.map((item) => {
          if (selectedTab === 0 && item.title === FilterDict.BookingStates) {
            return null;
          }

          return (
            <Dropdown
              className={cn('theme-dark dropdown', {
                status: item.title === FilterDict.BookingStates,
              })}
              disabled={isFilterLoading}
              icon={
                FilterLabel[item.title] === 'Transfer type'
                  ? getTransferIcon(item.selected?.value as TransferType)
                  : FilterIcon[item.title]
              }
              key={item.title}
              options={item.options}
              placeholder={FilterLabel[item.title] || item.title}
              searchable
              value={item.selected?.value || FilterLabel[item.title] || item.title}
              onChange={(value) =>
                handleFilterChange(item.title, item.options.find((i) => i.value === value)!)
              }
            />
          );
        })}

        {(!!(period[0] && period[1]) || !!selected.length) && (
          <Button
            className="btn btn-clear"
            leftIcon={<CloseRound />}
            text={t('common.btnClearAll')}
            variant="transparent"
            onClick={handleClear}
          />
        )}
      </section>

      <section className="filter-bar-tags">
        {!!selected.length &&
          selected.map((item: FilterSelected) => (
            <div key={item.key}>
              <Tag
                title={item.key.replace(/_/g, ' ')}
                value={item.value}
                onCancel={() => handleCancel(item.key)}
              />
            </div>
          ))}
      </section>
    </section>
  );
};

export default Filters;
