/* istanbul ignore file */
import { format } from 'date-fns';

import { DateFormat as DF } from '@common/types';
import type { BDDrive, DriveUpdate } from '@common/interfaces';
import type { DriveFormType } from '../schema/DriveForm.schema';

class Serializer {
  public static mapDriveEditToUpdate(data: DriveFormType, drive: BDDrive): DriveUpdate {
    return {
      comment_to_driver: data.commentDriver || null,
      comment_to_pax: data.commentPax || null,
      driver: data.driver,
      dropoff_date: format(new Date(data.dropoffDate), DF.ApiDate),
      dropoff_location: drive.dropoffLocation,
      dropoff_time: format(new Date(data.dropoffTime), DF.ApiTime),
      dropoff_town: drive.dropoffTown,
      is_active: drive.isActive,
      pickup_date: format(new Date(data.pickupDate), DF.ApiDate),
      pickup_location: drive.pickupLocation,
      pickup_time: format(new Date(data.pickupTime), DF.ApiTime),
      pickup_town: drive.pickupTown,
      scheduled_route_id: drive.scheduledRouteId,
      state: drive.state,
      uuid: drive.id,
      vehicle: data.vehicle,
    };
  }
}

export default Serializer;
