/* eslint-disable no-nested-ternary */
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, isAfter, isSameDay, parse, parseISO } from 'date-fns';
import { Switch } from '@mantine/core';
import FullCalendar, {
  DateSpanApi,
  DatesSetArg,
  EventApi,
  EventContentArg,
  EventDropArg,
} from '@fullcalendar/react';
import type { DateValue } from '@mantine/dates';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { useRepository } from '@context';
import { useFacility, useFeatureFlags } from '@common/hooks';
import { DateFormat as DF } from '@common/types';
import { Button, DateInputPicker, Loader, Modal, PageTitle } from '@components';
import { formatDateString, getErrors, Serializer } from '@common/utils';
import {
  BDRoute,
  BookingDetails,
  BookingDetailsApi,
  BookingState,
  DriveCreateExisted,
  DriveEditStatus,
  DriveStatus,
  DriveUpdateApi,
  EventExtProps,
  EventMoved,
  PlAcceptDrive,
  PlDrive,
  PlDriveFormData,
  PlDriver,
  PlDriverApi,
  PlDriverResource,
  PlEvent,
  PlResource,
  PlVehicle,
  PlVehicleApi,
  PlVehicleResource,
  ResViewType,
  Timeline,
  TransferType,
  TripType,
} from '@common/interfaces';
import { Close, Driver, PDF, Vehicle } from '@assets/svg/icons';
import {
  BookingPanel,
  DriveForm,
  DriveModal,
  DrivePreview,
  EventContent,
  EventDropPreview,
  Planner,
  ShuttleConfigModal,
} from './components';
import {
  checkOverlapEventAllow,
  checkSharedDriveEvent,
  checkShuttleEventOverlap,
  checkShuttleWithinRange,
  formatScheduleDriveOnAccept,
  getDriverResource,
  getVehicleResource,
} from './utils';
import './MobilityPlannerPage.styles.scss';

const MobilityPlannerPage: FC = () => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { bookingRepository, plannerRepo } = useRepository();
  const { id: bookingId, driveId } = useParams<{ id: string; driveId?: string }>();
  const { flags } = useFeatureFlags();
  const { t } = useTranslation();
  const { agencyName, facility, facilityId: fid } = useFacility();
  const { agencyId, city, country } = facility;

  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [isFileLoading, setFileLoading] = useState<boolean>(false);
  const [lastDayOfWeek, setLastDayOfWeek] = useState<Date | null>(null);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [resourceType, setResourceType] = useState<ResViewType>(ResViewType.Vehicle);
  const [timeline, setTimeline] = useState<Timeline>(Timeline.Day);

  const [booking, setBookingData] = useState<BookingDetails>();
  const [drivers, setDrivers] = useState<PlDriver[]>([]);
  const [drives, setDrives] = useState<PlDrive[]>([]);
  const [events, setEvents] = useState<PlEvent[]>([]);
  const [vehicles, setVehicles] = useState<PlVehicle[]>([]);

  const [acceptDrives, setAcceptDrives] = useState<PlAcceptDrive[]>([]);
  const [acceptEvents, setAcceptEvents] = useState<PlEvent[]>([]);
  const [formDriveData, setFormDriveData] = useState<PlDriveFormData | null>(null);
  const [formShuttleConfig, setFormShuttleConfig] = useState<Partial<PlDrive> | null>(null);
  const [eventDropData, setEventDropData] = useState<EventDropArg | null>(null);
  const [scheduledDrive, setScheduledDrive] = useState<EventExtProps | null>(null);
  const [scheduledResource, setScheduledResource] = useState<PlResource | null>(null);

  const date = currentDate ? format(currentDate, DF.ApiDate) : undefined;
  const isEditDriveProcess = !!driveId;
  const isAcceptProcess = !!bookingId && !isEditDriveProcess;
  const plannerDragDrop = flags.plannerDragDrop?.is_active;
  const showGoogleLocation = flags.showGoogleLocation?.is_active;
  const showQuestEvents = flags.showQuestEvent?.is_active;
  const showShuttleEvents = flags.showShuttleEvents?.is_active;

  const { refetch: getBookingDetails } = useQuery(
    `get-booking`,
    () => bookingRepository.getBookingDetails(fid, bookingId!),
    {
      enabled: !!bookingId,
      onSuccess: (data: BookingDetailsApi) => {
        setBookingData(Serializer.formatBookingDetails(data));
      },
      onError: (e: any) => {
        if (e.response) toast.error(getErrors(e.response.data));
      },
    },
  );

  const drivesQuery = useQuery(
    [`get-drives`, currentDate, timeline],
    () => plannerRepo.getDrives(fid, { agencyId, date, isWeek: timeline === Timeline.Week }),
    { enabled: !!agencyId && !!currentDate },
  );

  const shuttleQuery = useQuery(
    [`get-shuttle-events`, currentDate],
    () => plannerRepo.getShuttleEvents(fid, { agencyId, date, isWeek: timeline === Timeline.Week }),
    { enabled: !!agencyId && !!currentDate && !!showShuttleEvents },
  );

  const questWorkflowQuery = useQuery(
    [`get-quest-workflows`, currentDate],
    () => plannerRepo.getQuestWorkflows(fid, { agencyId, date }),
    { enabled: !!agencyId && !!currentDate && !!showQuestEvents },
  );

  const { data: quest, isLoading: isQuestLoading, refetch: getQuestWorkflow } = questWorkflowQuery;
  const { data: shuttle, isLoading: isShuttleLoading, refetch: getShuttleEvents } = shuttleQuery;
  const { data: drivesData, isLoading: isDrivesLoading, refetch: getDrives } = drivesQuery;

  const { isLoading: isDriversLoading } = useQuery(
    [`get-drivers`, currentDate],
    () => plannerRepo.getDrivers(fid, { agencyId, date }),
    {
      enabled: !!agencyId && !!currentDate,
      onSuccess: (data: PlDriverApi[]) => {
        const sortedDrivers = data
          .map(Serializer.formatDriverToPlanner)
          .sort((a, b) => Number(a.isDisabled) - Number(b.isDisabled));
        setDrivers(sortedDrivers);
      },
    },
  );

  const { isLoading: isVehiclesLoading } = useQuery(
    [`get-vehicles`, currentDate],
    () => plannerRepo.getVehicles(fid, { agencyId, date }),
    {
      enabled: !!agencyId && !!currentDate,
      onSuccess: (data: PlVehicleApi[]) => {
        const sortedVehicles = data
          .map(Serializer.formatVehicleToPlanner)
          .sort((a, b) => Number(a.isDisabled) - Number(b.isDisabled));
        setVehicles(sortedVehicles);
      },
    },
  );

  const { mutate: createDrives, isLoading: isCreateDrivesLoading } = useMutation(
    'create-drives',
    (data: DriveCreateExisted) =>
      plannerRepo.createDrives(fid, data, { agencyId, skipShuttle: !showShuttleEvents }),
    {
      onSuccess: () => {
        toast.success(t('mobility.msgDrivePublished'));
        navigate(`${location.pathname.split('/').slice(0, -2).join('/')}`);
      },
      onError: (e: any) => {
        toast.error(getErrors(e?.response?.data));
      },
    },
  );

  const { mutate: updateDrive, isLoading: isDriveUpdating } = useMutation(
    'planner-event-update',
    (data: DriveUpdateApi) => plannerRepo.updatePlannerDrive(fid, data?.uuid, data),
    {
      onSuccess: () => {
        getDrives();
        toast.success(t('mobility.msgDriverUpdated'));
      },
    },
  );

  const clearEventDrop = useCallback(() => {
    if (eventDropData) {
      const eventElement = document.querySelector(`.fc-event-id-${eventDropData?.event?.id}`);
      if (eventElement) eventElement.classList.remove('fc-event-moved');

      setEventDropData(null);
    }
  }, [eventDropData]);

  const handleGridDoubleClick = async (resDate: Date, id: string, view: string): Promise<void> => {
    if (isAcceptProcess || eventDropData) return;

    const resource =
      resourceType === ResViewType.Vehicle
        ? vehicles.find((i) => i.id === id)
        : resourceType === ResViewType.Driver
        ? drivers.find((i) => i.id === id)
        : null;

    if (resource?.isDisabled) return;

    if (view.includes('Day')) {
      const resKey = resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId';
      setFormDriveData({ resDate, [resKey]: id });
    }
  };

  const handleEventAllow = (span: DateSpanApi, movingEvent: EventApi | null): boolean => {
    const { bookingTypeOfTrip, shuttleConfig, state } = movingEvent?.extendedProps as EventExtProps;
    const { start, end, resource } = span;
    const { id: resourceId } = resource!;

    const isDaySame = currentDate && isSameDay(start, currentDate) && isSameDay(end, currentDate);

    if (!isDaySame) return false;

    if (resource?.extendedProps?.isDisabled || shuttleConfig || state !== DriveStatus.NotStarted) {
      return false;
    }

    const filteredDrives = drives.filter((d) => d.shuttleConfig);
    const checkArgs = { drives: filteredDrives, start, end, resourceId, resourceType };
    const isShuttleOverlap = checkShuttleEventOverlap(checkArgs);

    if (isShuttleOverlap) {
      return bookingTypeOfTrip === TripType.OneWay && !!checkShuttleWithinRange(checkArgs);
    }

    return checkOverlapEventAllow({ drives, start, end, resourceId, resourceType });
  };

  const handleEventDrop = async (eventDrop: EventDropArg): Promise<void> => {
    const { event, newResource, revert } = eventDrop;
    if (eventDropData && event.id !== eventDropData?.event.id) return revert();

    const { start, end, extendedProps } = event;
    const { id: resourceId } = newResource || {};
    const resKey = resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId';

    const sharedDrive = checkSharedDriveEvent({
      drives: drives.filter((i: PlDrive) => i.id !== event.id),
      start: start!,
      end: end!,
      resourceId: resourceId || extendedProps[resKey],
      resourceType,
    });

    if (sharedDrive) {
      event.setProp('title', sharedDrive.shuttleConfig ? 'to shuttle' : 'to a rideshare trip');
      event.setExtendedProp('driverId', sharedDrive.driverId);
      event.setExtendedProp('driverFirstName', sharedDrive.driverFirstName);
      event.setExtendedProp('driverLastName', sharedDrive.driverLastName);
      event.setExtendedProp('vehicleId', sharedDrive.vehicleId);
      event.setExtendedProp('vehicleLabel', sharedDrive.vehicleLabel);
    } else if (resourceId) event.setExtendedProp(resKey, resourceId);

    setEventDropData(eventDrop);

    const observer = new MutationObserver(() => {
      const eventElement = document.querySelector(`.fc-event-id-${event.id}`);
      if (eventElement) {
        eventElement.classList.add('fc-event-moved');
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  };

  const handleEventDropCancel = async (): Promise<void> => {
    if (!eventDropData) return;

    const { id, end, start } = eventDropData.oldEvent;

    if (start && end) {
      setEvents((prev: PlEvent[]) =>
        prev.map((event) => (event.id === id ? { ...event, start, end } : event)),
      );
    }

    clearEventDrop();
  };

  const handleEventDropSubmit = async (): Promise<void> => {
    if (!eventDropData) return;

    const { end, extendedProps, start } = eventDropData.event;

    const payload = Serializer.mapPlannerEventToUpdate({
      ...extendedProps,
      dropoffTime: format(end!, DF.ApiTimeFull),
      pickupTime: format(start!, DF.ApiTimeFull),
    } as PlDrive);

    updateDrive(payload);
    clearEventDrop();
  };

  const handleEventDropEdit = async (): Promise<void> => {
    if (!timeline.includes('Day') || !eventDropData) return;

    const {
      event: { id, end, extendedProps, start },
      newResource,
    } = eventDropData!;
    const relatedDrive = drives.find((i: PlDrive) => i.id === id)!;
    const resKey = resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId';
    const oppositeResKey = resourceType === ResViewType.Driver ? 'vehicleId' : 'driverId';

    if (!relatedDrive) return;

    const shuttleWithinRange = checkShuttleWithinRange({
      drives: drives.filter((d) => d.shuttleConfig),
      start: start!,
      end: end!,
      resourceId: newResource?.id!,
      resourceType,
    });

    setFormDriveData({
      driveStatus: relatedDrive?.state,
      id,
      movedDrive: {
        ...relatedDrive,
        dropoffTime: format(end!, DF.ApiTimeFull),
        pickupTime: format(start!, DF.ApiTimeFull),
        [oppositeResKey]: extendedProps[oppositeResKey],
        ...(newResource
          ? resourceType === ResViewType.Driver
            ? { driverId: newResource.id }
            : { vehicleId: newResource.id }
          : {}),
        ...(shuttleWithinRange
          ? { driverId: shuttleWithinRange.driverId, eventMoved: EventMoved.InShuttle }
          : {}),
      },
      resDate: start!,
      showGoogleLocation,
      [resKey]: newResource?.id || relatedDrive[resKey],
    });
  };

  const handleScheduleDriveClick = async (drive: EventExtProps): Promise<void> => {
    if (isAcceptProcess) {
      const acceptDrive = acceptDrives?.find((d: PlAcceptDrive) => !d.accepted && !d.rejected);
      if (acceptDrive) {
        const formattedDrive = formatScheduleDriveOnAccept(acceptDrive, drive);
        setScheduledDrive(formattedDrive);
      }
    } else if (timeline.includes('Day')) {
      const resKey = resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId';
      setFormDriveData({
        resDate: parse(drive.pickupDate, 'dd/MM/yyyy', new Date()),
        scheduled: drive,
        [resKey]: drive[resKey],
      });
    }
  };

  const handleEventDoubleClick = async ({ event }: EventContentArg): Promise<void> => {
    if (eventDropData) return;

    const { editableState, id, scheduledRouteId, state } = event.extendedProps;
    const notEditable =
      isAcceptProcess || isEditDriveProcess || !state || editableState === DriveEditStatus.Grey;

    if (scheduledRouteId && !state) return setFormShuttleConfig(event.extendedProps);
    if (!notEditable) {
      const drive = scheduledRouteId
        ? drives.find((i) => i.scheduledRouteId === scheduledRouteId && i.shuttleConfig)
        : null;
      setFormDriveData({
        resDate: currentDate,
        driveStatus: state,
        id,
        ...(scheduledRouteId && drive && { stops: drive?.shuttleConfig?.stops }),
      });
    }
  };

  const handleViewChange = ({ startStr, view }: DatesSetArg): void => {
    const startDate = parseISO(startStr);

    if (view.type?.includes('Week') && currentDate && !lastDayOfWeek) setLastDayOfWeek(currentDate);

    setTimeline(view.type?.includes('Day') ? Timeline.Day : Timeline.Week);

    if (view.type?.includes('Day') && lastDayOfWeek) {
      setCurrentDate(lastDayOfWeek);
      setLastDayOfWeek(null);
      return;
    }

    if (currentDate && !isSameDay(currentDate, startDate)) setCurrentDate(startDate);
  };

  const handleResourceAddClick = async (resourceId: string): Promise<void> => {
    if (isAcceptProcess) {
      setScheduledResource({
        [resourceType === ResViewType.Driver ? 'driverId' : 'vehicleId']: resourceId,
      });
    }
  };

  const handleFormSubmitModal = async (): Promise<void> => {
    getDrives();
    setFormDriveData(null);
    setFormShuttleConfig(null);
    if (eventDropData) clearEventDrop();
    if (showQuestEvents) getQuestWorkflow();
    if (showShuttleEvents) getShuttleEvents();
  };

  const renderEventContent = (eventContent: EventContentArg) => (
    <EventContent
      drivers={drivers}
      event={eventContent}
      isEditProcess={isEditDriveProcess}
      resType={resourceType}
      showShuttle={showShuttleEvents}
      vehicles={vehicles}
      onScheduleClick={handleScheduleDriveClick}
      onDoubleClick={handleEventDoubleClick}
    />
  );

  const renderResourceContent = (
    content: PlDriverResource | PlVehicleResource,
    showShuttle: boolean,
  ) => {
    const showBtnAdd = isAcceptProcess && showShuttle;
    return resourceType === ResViewType.Driver
      ? getDriverResource(content, showBtnAdd, handleResourceAddClick)
      : getVehicleResource(content, showBtnAdd, handleResourceAddClick);
  };

  const submitScheduledDrive = async (drive: {
    dropoffTime: string;
    pickupTime: string;
  }): Promise<void> => {
    if (scheduledDrive) {
      const currentDrive = acceptDrives.find((d: PlAcceptDrive) => !d.accepted && !d.rejected);
      const index = acceptDrives.findIndex((d: PlAcceptDrive) => !d.accepted && !d.rejected);

      if (currentDrive && index !== -1) {
        const { driverId, dropoffDate, pickupDate, scheduledRouteId, vehicleId } = scheduledDrive;
        const newAcceptDrives = [...acceptDrives];
        newAcceptDrives[index] = {
          ...currentDrive,
          driverId,
          dropoffDate: formatDateString(dropoffDate, DF.ApiDateAlt, DF.ApiDate),
          dropoffTime: drive.dropoffTime,
          isScheduled: true,
          pickupDate: formatDateString(pickupDate, DF.ApiDateAlt, DF.ApiDate),
          pickupTime: drive.pickupTime,
          scheduledRouteId,
          vehicleId,
        };
        setAcceptDrives(newAcceptDrives);
      }

      setScheduledDrive(null);
    }
  };

  const exportToPDF = useCallback(async (): Promise<void> => {
    const planner = document.querySelector('.fc-timeline') as HTMLElement;

    if (planner) {
      setFileLoading(true);
      await html2canvas(planner, { logging: true, allowTaint: false, useCORS: true })
        .then((canvas) => {
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF('p', 'mm', 'a4');

          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 206;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          pdf.save(`Drive_export_${country}_${city}_${agencyName}.pdf`);
        })
        .finally(() => {
          setFileLoading(false);
        });
    }
  }, [agencyName, city, country]);

  const headerToolbarActions = useMemo(
    () => (
      <div className="header-toolbar header-toolbar-actions" key="header-toolbar-actions">
        {timeline === Timeline.Day ? (
          <div className="filter-field">
            <input
              className="input-search"
              placeholder={t('mobility.filterByPassengerEmail')}
              type="text"
              value={searchKeyword}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSearchKeyword(event.currentTarget.value);
              }}
            />
            {searchKeyword?.length ? (
              <Button
                className="btn-reset"
                rightIcon={<Close />}
                variant="primary"
                onClick={() => {
                  setSearchKeyword('');
                }}
              />
            ) : null}
          </div>
        ) : null}

        <Button
          className="btn btn-export"
          disabled={isFileLoading}
          icon={<PDF />}
          text={`${t('common.btnExport')} PDF`}
          variant="submit"
          onClick={exportToPDF}
        />
      </div>
    ),
    [exportToPDF, isFileLoading, searchKeyword, t, timeline],
  );

  const headerToolbarCalendar = useMemo(
    () => (
      <div
        className="header-toolbar header-toolbar-calendar"
        data-testid="mobility-planner-calendar-date-picker"
        key="header-toolbar-calendar"
      >
        <DateInputPicker
          iconPosition="left"
          showIconOnly
          value={currentDate}
          onChange={(value) => {
            if (value) {
              calendarRef.current?.getApi().gotoDate(value as DateValue);
              clearEventDrop();
            }
          }}
        />
      </div>
    ),
    [clearEventDrop, currentDate],
  );

  const headerToolbarResourceSwitcher = useMemo(
    () => (
      <div
        className="header-toolbar header-toolbar-resource-switcher"
        key="header-toolbar-resource-switcher"
      >
        <Switch
          classNames={{ track: 'resource-switcher' }}
          size="md"
          onLabel={<Driver />}
          offLabel={<Vehicle />}
          onChange={(e) => {
            clearEventDrop();
            setResourceType(e.currentTarget.checked ? ResViewType.Driver : ResViewType.Vehicle);
          }}
        />
      </div>
    ),
    [clearEventDrop],
  );

  useEffect(() => {
    if (isDrivesLoading || isDriversLoading || isQuestLoading || isShuttleLoading) {
      return;
    }

    let mappedDrives: PlDrive[] = [];
    let mappedEvents: PlEvent[] = [];

    if (showShuttleEvents && shuttle) {
      mappedDrives = shuttle.map(Serializer.formatShuttleToDrives);
      mappedEvents = Serializer.formatShuttleToEvents(shuttle, resourceType);
    }

    if (drivesData) {
      mappedDrives = [...mappedDrives, ...drivesData.map(Serializer.formatDrives)];
      mappedEvents = [
        ...mappedEvents,
        ...Serializer.formatDrivesToEvents({ data: drivesData, resourceType }),
      ];
    }

    if (showQuestEvents && quest) {
      mappedDrives = [...mappedDrives, ...Serializer.formatQuestToDrives(quest)];
      mappedEvents = [
        ...mappedEvents,
        ...Serializer.formatQuestToEvents(drivers, resourceType, quest),
      ];
    }

    setDrives(mappedDrives);
    setEvents(mappedEvents);
  }, [
    drivers,
    drivesData,
    isDrivesLoading,
    isDriversLoading,
    isQuestLoading,
    isShuttleLoading,
    resourceType,
    quest,
    showQuestEvents,
    showShuttleEvents,
    shuttle,
    timeline,
  ]);

  useEffect(() => {
    if (!acceptDrives.length) return;

    const filteredDrives = acceptDrives.filter((i) => !i.rejected);
    const isFinite = filteredDrives.every((drive) => drive.accepted);

    if (!filteredDrives.length) {
      navigate(`${location.pathname.split('/').slice(0, -2).join('/')}`);
      return;
    }

    if (filteredDrives.length && isFinite) {
      const acceptDrivesData = acceptDrives.filter((i) => i.accepted);
      const scheduled = acceptDrivesData.find((i) => i.transferType === TransferType.Shuttle);
      const transferType = acceptDrivesData[0].transferType! || booking?.transferType;
      const shuttleId = transferType === TransferType.Shuttle ? scheduled?.scheduledRouteId : null;

      createDrives({
        agency_id: agencyId!,
        booking: booking?.id!,
        booking_extra: {
          transfer_type:
            !showShuttleEvents && transferType === TransferType.Shuttle
              ? TransferType.InTown
              : transferType,
          uuid: booking?.id!,
        },
        drives: Serializer.mapAcceptingDataToDrives(acceptDrivesData, shuttleId!),
      });

      return;
    }

    const prevDrive = acceptDrives.filter((d: PlAcceptDrive) => d.accepted).pop();
    const currentDrive = acceptDrives.find((d: PlAcceptDrive) => !d.accepted && !d.rejected);

    const prevFullDate =
      prevDrive &&
      parse(
        `${prevDrive.dropoffDate} ${prevDrive.dropoffTime}`,
        `${DF.ApiDate} - ${DF.ApiTime}`,
        new Date(),
      );

    const driveFullDate =
      currentDrive &&
      parse(
        `${currentDrive.pickupDate} ${currentDrive.pickupTime}`,
        `${DF.ApiDate} - ${DF.ApiTime}`,
        new Date(),
      );

    if (isAfter(prevFullDate!, driveFullDate!)) {
      setCurrentDate(parseISO(prevDrive?.pickupDate!));
    } else {
      setCurrentDate(parseISO(currentDrive?.pickupDate!));
    }

    const acceptanceEvents = acceptDrives.map((data: PlAcceptDrive) => ({
      className: 'fc-event-preview',
      end: parseISO(`${data.dropoffDate} ${data.dropoffTime}`),
      id: data.id,
      resourceId: resourceType === ResViewType.Vehicle ? data.vehicleId! : data.driverId!,
      start: parseISO(`${data.pickupDate} ${data.pickupTime}`),
      title: data.driverFirstName || '',
      extendedProps: data,
    }));

    setAcceptEvents(acceptanceEvents);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptDrives, booking, resourceType]);

  useEffect(() => {
    if (booking?.routes?.length && isAcceptProcess) {
      const formattedRoutes = booking?.routes.map((item: BDRoute) => ({
        ...item,
        accepted: false,
        agencyId: booking?.agencyFk?.id,
        bookingId: booking?.id,
        focalPointFirstName: booking?.bookingAgentFirstName,
        focalPointLastName: booking?.bookingAgentLastName,
        isHide: true,
        isTemporary: true,
        passengerAgency: booking?.agencyFk?.completeName,
        passengerEmail: booking?.email,
        passengerFirstName: booking?.firstName,
        passengerLastName: booking?.lastName,
        passengerPax: booking?.pax,
        passengerPhone: booking?.phoneNumber,
        passengerRemarks: booking?.remarks,
        rejected: item.state === BookingState.Rejected,
        transferType: booking?.transferType,
      }));

      setAcceptDrives(formattedRoutes);
    }
  }, [booking, isAcceptProcess]);

  useEffect(() => {
    if (booking && isEditDriveProcess) setCurrentDate(parseISO(booking.drives[0]?.pickupDate!));
  }, [booking, isEditDriveProcess]);

  useEffect(() => {
    if (!currentDate && !bookingId) setCurrentDate(new Date());
    if (currentDate) calendarRef.current?.getApi().gotoDate(currentDate);
  }, [bookingId, currentDate]);

  const activeDrivers = useMemo(() => drivers.filter((i: PlDriver) => !i.isDisabled), [drivers]);
  const activeVehicles = useMemo(
    () => vehicles.filter((i: PlVehicle) => !i.isDisabled),
    [vehicles],
  );

  const visibleEvents = useMemo(() => {
    const eventData = [...events, ...acceptEvents].filter((e: PlEvent) => !e.extendedProps?.isHide);

    return searchKeyword.length
      ? eventData.filter((item: PlEvent) =>
          item.extendedProps?.passengerEmail?.toLowerCase().includes(searchKeyword.toLowerCase()),
        )
      : eventData;
  }, [acceptEvents, events, searchKeyword]);

  const modalDriveTitle = useMemo(
    () => (formDriveData?.id ? t('common.editDrive') : t('planner.createDrive')),
    [formDriveData, t],
  );

  const isLoading =
    isCreateDrivesLoading ||
    isDriveUpdating ||
    isDrivesLoading ||
    isDriversLoading ||
    isQuestLoading ||
    isShuttleLoading ||
    isVehiclesLoading;

  return (
    <section className="mobility-planner-page">
      <PageTitle title={city && country ? `${city}, ${country} | ${agencyName}` : ''} />
      <Loader fullScreen spinning={isLoading} />

      {isAcceptProcess && booking && !!acceptDrives && (
        <BookingPanel
          acceptDrives={acceptDrives}
          booking={booking}
          drivesData={drives}
          drivers={activeDrivers}
          isAccept
          resource={[scheduledResource, setScheduledResource]}
          showShuttle={showShuttleEvents}
          vehicles={activeVehicles}
          setAcceptDrives={setAcceptDrives}
          setDate={setCurrentDate}
        />
      )}

      {isEditDriveProcess && booking && !isLoading && !!Object.keys(flags).length && (
        <BookingPanel
          booking={booking}
          drivers={activeDrivers}
          isEdit
          showShuttle={showShuttleEvents}
          vehicles={activeVehicles}
          onDriveCancel={() => {
            getBookingDetails();
            getDrives();
            if (showQuestEvents) getQuestWorkflow();
            if (showShuttleEvents) getShuttleEvents();
          }}
        />
      )}

      <Planner
        editable={plannerDragDrop && !isLoading}
        eventContent={renderEventContent}
        events={visibleEvents}
        headerToolbar={[headerToolbarActions, headerToolbarCalendar, headerToolbarResourceSwitcher]}
        ref={calendarRef}
        resourceContent={renderResourceContent}
        resources={resourceType === ResViewType.Driver ? drivers : vehicles}
        onDoubleClick={handleGridDoubleClick}
        onEventAllow={handleEventAllow}
        onEventDrop={handleEventDrop}
        onViewChange={handleViewChange}
      />

      <EventDropPreview
        data={eventDropData}
        drives={drives}
        resourceType={resourceType}
        onCancel={handleEventDropCancel}
        onEdit={handleEventDropEdit}
        onSubmit={handleEventDropSubmit}
      />

      <DrivePreview
        data={scheduledDrive}
        onCancel={() => setScheduledDrive(null)}
        onSubmit={submitScheduledDrive}
      />

      {showShuttleEvents && formDriveData && (
        <DriveModal
          drivers={activeDrivers}
          drives={drives}
          formData={formDriveData}
          showGoogleLocation={showGoogleLocation}
          vehicles={activeVehicles}
          onCancel={() => setFormDriveData(null)}
          onSubmit={handleFormSubmitModal}
        />
      )}

      {showShuttleEvents && formShuttleConfig && (
        <ShuttleConfigModal
          drivers={activeDrivers}
          formData={formShuttleConfig}
          vehicles={activeVehicles}
          onCancel={() => {
            setFormShuttleConfig(null);
          }}
          onSubmit={handleFormSubmitModal}
        />
      )}

      {/* TODO: remove old form */}
      {!showShuttleEvents && (
        <Modal
          className="drive-form-popup"
          data-testid="mobility-planner-drive-form"
          showBtnClose
          title={modalDriveTitle}
          variant="default"
          visible={!!formDriveData}
          onClose={() => setFormDriveData(null)}
        >
          <DriveForm
            drivers={activeDrivers}
            drives={drives}
            formData={formDriveData}
            vehicles={activeVehicles}
            onCancel={() => setFormDriveData(null)}
            onSubmit={handleFormSubmitModal}
          />
        </Modal>
      )}
    </section>
  );
};

export default MobilityPlannerPage;
