import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { format, isValid, parse } from 'date-fns';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

import { TIME_INTERVAL } from '@common/constants';
import { DateFormat } from '@common/types';
import { Clock } from '@assets/svg/icons/logbook';
import './InputTimePicker.styles.scss';

const isDate = (value: unknown): value is Date => value instanceof Date;

const parseTimeStringToDate = (time: string): Date | null => {
  if (!time) return null;

  const date = parse(time, 'HH:mm', new Date());

  if (isValid(date)) return date;

  return null;
};

type DatePickerProps = Omit<ReactDatePickerProps, 'defaultValue' | 'value' | 'onChange'> & {
  onChange?: (time: Date | string | null) => void;
};

type DatePickerVariant = 'default' | 'unstyled';

export type InputTimePickerProps<T extends FieldValues> = UseControllerProps<T> &
  DatePickerProps & {
    className?: string;
    label?: string;
    placeholder?: string;
    testId?: string;
    valueTypeDate?: boolean;
    variant?: DatePickerVariant;
  };

export function InputTimePicker<T extends FieldValues>({
  className,
  control,
  defaultValue,
  label,
  maxTime,
  minTime,
  name,
  placeholder,
  required,
  rules,
  shouldUnregister,
  testId,
  valueTypeDate = false,
  variant = 'default',
  onChange,
  ...props
}: InputTimePickerProps<T>) {
  const {
    field: { ref, value, onChange: fieldOnChange, ...field },
    fieldState,
  } = useController<T>({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
  });

  const handleDateChange = (date: Date | null) => {
    if (valueTypeDate) {
      fieldOnChange(date);
      onChange?.(date);
    } else {
      const formattedTime = date ? format(date, DateFormat.ApiTime) : null;
      fieldOnChange(formattedTime);
      onChange?.(formattedTime);
    }
  };

  const selectedDate = isDate(value) ? value : parseTimeStringToDate(value);

  return (
    <div className={cn(`time-picker time-picker-${variant}`, className)}>
      {label && (
        <label className="time-picker-label" htmlFor="time-picker">
          {label} {required && <span>*</span>}
        </label>
      )}
      <div className="time-picker-input" data-testid={testId}>
        <DatePicker
          className={cn({ invalid: fieldState.error })}
          dateFormat="HH:mm"
          dropdownMode="select"
          id="time-picker"
          maxTime={maxTime || parse('23:45', DateFormat.ApiTime, new Date())}
          minTime={minTime || parse('00:00', DateFormat.ApiTime, new Date())}
          required={required}
          selected={selectedDate}
          shouldCloseOnSelect
          showTimeSelect
          showTimeSelectOnly
          timeFormat="HH:mm"
          timeIntervals={TIME_INTERVAL}
          timeCaption={undefined}
          onChange={handleDateChange}
          placeholderText={placeholder}
          {...field}
          {...props}
        />
        {variant === 'default' && <Clock />}
      </div>
      {fieldState.error && <span className="field-error">{fieldState.error.message}</span>}
    </div>
  );
}
